import * as actionTypes from "../actionTypes";

export const getUsersList = (data) => {
    console.log("get user list action ",data);
    return {
        type: actionTypes.GET_USERS_LIST_START,
        payload: data,
    };
};
export const getUsersListSuccess = (data) => {
    //console.log("get user list success action ",data);
    return {
        type: actionTypes.GET_USERS_LIST_SUCCESS,
        payload: data,
    };
};
export const getUsersListFailure = (data) => {
    return {
        type: actionTypes.GET_USERS_LIST_FAILURE,
        payload: data,
    };
};
export const getWaitersList = (data) => {
    console.log("get waiter list action ",data);
    return {
        type: actionTypes.GET_WAITERS_LIST_START,
        payload: data,
    };
};
export const getWaitersListSuccess = (data) => {
    console.log("get waiters list success action ",data);
    return {
        type: actionTypes.GET_WAITERS_LIST_SUCCESS,
        payload: data,
    };
};
export const getWaitersListFailure = (data) => {
    return {
        type: actionTypes.GET_WAITERS_LIST_FAILURE,
        payload: data,
    };
};
export const getUserData = (data) => {
    return {
        type: actionTypes.GET_USER_DATA_START,
        payload: data,
    };
};
export const getUserDataSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_DATA_SUCCESS,
        payload: data,

    };
};
export const getUserDataFailure = (data) => {
    return {
        type: actionTypes.GET_USER_DATA_FAILURE,
        payload: data,
    };
};

export const addNewUser = (data) => {
    console.log("add new user action",data)
    return {
        type: actionTypes.ADD_NEW_USER_START,
        payload: data,
    };
};
export const addNewUserSuccess = (data) => {
    console.log("ADD_NEW_USER_SUCCESS",data)

    return {
        type: actionTypes.ADD_NEW_USER_SUCCESS,
        payload: data,
    };
};
export const addNewUserFailure = (data) => {
    return {
        type: actionTypes.ADD_NEW_USER_FAILURE,
        payload: data,
    };
};

export const updateUser = (data) => {
    console.log("update user data action->",data);
    return {
        type: actionTypes.UPDATE_USER_START,
        payload: data,
    };
};
export const updateUserSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        payload: data,
    };
};
export const updateUserFailure = (data) => {
    return {
        type: actionTypes.UPDATE_USER_FAILURE,
        payload: data,
    };
};

export const listUserPermission = (data) => {
    return {
        type: actionTypes.LIST_USER_PERMISSION_START,
        payload: data,
    };
};
export const listUserPermissionSuccess = (data) => {
    return {
        type: actionTypes.LIST_USER_PERMISSION_SUCCESS,
        payload: data,
    };
};
export const listUserPermissionFailure = (data) => {
    return {
        type: actionTypes.LIST_USER_PERMISSION_FAILURE,
        payload: data,
    };
};

export const manageUserPermission = (data) => {
    return {
        type: actionTypes.MANAGE_USER_PERMISSION_START,
        payload: data,
    };
};
export const manageUserPermissionSuccess = (data) => {
    return {
        type: actionTypes.MANAGE_USER_PERMISSION_SUCCESS,
        payload: data,
    };
};
export const manageUserPermissionFailure = (data) => {
    return {
        type: actionTypes.MANAGE_USER_PERMISSION_FAILURE,
        payload: data,
    };
};

export const listModule = (data) => {
    return {
        type: actionTypes.LIST_MODULE_START,
        payload: data,
    };
};
export const listModuleSuccess = (data) => {
    return {
        type: actionTypes.LIST_MODULE_SUCCESS,
        payload: data,
    };
};
export const listModuleFailure = (data) => {
    return {
        type: actionTypes.LIST_MODULE_FAILURE,
        payload: data,
    };
};

///////////////userServicesList///////
export const setUserServicesList = (data) => {
    return {
        type: actionTypes.SET_USER_SERVICES_LIST,
        payload: data,
    };
};

export const deleteUser = (data) => {
    return {
        type: actionTypes.DELETE_USER_START,
        payload: data,
    };
};
export const deleteUserSuccess = (data) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS,
        payload: data,
    };
};
export const deleteUserFailure = (data) => {
    return {
        type: actionTypes.DELETE_USER_FAILURE,
        payload: data,
    };
};

export const listLoginHistory = (data) => {
    return {
        type: actionTypes.LIST_LOGIN_HISTORY_START,
        payload: data,
    };
};
export const listLoginHistorySuccess = (data) => {
    return {
        type: actionTypes.LIST_LOGIN_HISTORY_SUCCESS,
        payload: data,
    };
};
export const listLoginHistoryFailure = (data) => {
    return {
        type: actionTypes.LIST_LOGIN_HISTORY_FAILURE,
        payload: data,
    };
};

export const messageHandler = () => {
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};
