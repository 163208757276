 import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import CategoryService from '../../services/category.service'


function* getParentCategoryList(action) {
  try {
      const response = yield CategoryService.listCategories(action.payload);
      console.log('getParentCategoryList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        console.log('response.data userSaga ',response.data);
        yield put(actions.getParentCategoryListSuccess(response.data));
      } else {
        yield put(actions.getParentCategoryListFailure("network error"));
      }
    }
    catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getParentCategoryListFailure(error));
  }
}

function* getChildCategoryList(action) {
  try {
    console.log('getCategoryList action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const category = action.payload?.catName;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      let params = {page, perPage, category, search, status};

      const response = yield CategoryService.listChildCategories(params);
      console.log('getChildCategoryList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        console.log('response.data userSaga ',response.data);
        yield put(actions.getChildCategoryListSuccess(response.data));
      } else {
        yield put(actions.getChildCategoryListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getChildCategoryListFailure(error));
  }
}

function* addCategory(action){
  try {
    console.log('addCategory payload ',action);
  
      let response;
      let payload = action.payload;
      console.log("payload in upload File Saga",payload);
      const { title, file, restaurant_branch_id } = action.payload;
      console.log("file in payload",payload);
       // Create FormData object to send file
       const formData = new FormData();
       formData.append('title', title);
       formData.append('restaurant_branch_id', restaurant_branch_id);
       formData.append('file', file);
       console.log("formData",formData);
      let create = payload.create;
      delete payload.create;
      
      if (create) {
        // create user flow
        delete payload._id
      }
      response = yield CategoryService.addCategory(formData);
      if (response?.data.statusCode === 201) {
        //console.log('response.data GallerySagas ',response.data);
        yield put(actions.addCategorySuccess(response.data));
      } else {
        yield put(actions.addCategoryFailure("network error"));
      }
    } catch (err) {
      // console.log('er ', err)
      let error = err?.response?.data?.message;
      if (!error) {
        error = "network error"
      }
    }
}

function* updateCategory(action){
  try {
      let payload = action.payload;
      console.log("update category payload",payload);
      const { title, file, restaurant_branch_id } = payload;
      const formData = new FormData();
      formData.append('title', title);
      formData.append('restaurant_branch_id', restaurant_branch_id);
      formData.append('file', file);
      console.log("formData",formData);
      const categoryId = payload?._id;
      const response = yield CategoryService.updateCategory(formData,categoryId);//params
      if (response?.data.statusCode === 200) {
        //console.log('response.data GallerySagas ',response.data);
        yield put(actions.updateCategorySuccess(response.data));
      } else {
        yield put(actions.updateCategoryFailure("network error"));
      }
    } catch (err) {
      // console.log('er ', err)
      let error = err?.response?.data?.message;
      if (!error) {
        error = "network error"
      }
    }
}

function* getParentCategoryListSaga() {
  yield takeEvery(actionsTypes.GET_PARENT_CATEGORY_LIST_START, getParentCategoryList);
}

function* getChildCategoryListSaga() {
  yield takeEvery(actionsTypes.GET_CHILD_CATEGORY_LIST_START, getChildCategoryList);
}

function* addCategorySaga(){
  yield takeEvery(actionsTypes.ADD_CATEGORY_START, addCategory);
}

function* updateCategorySaga(){
  yield takeEvery(actionsTypes.UPDATE_CATEGORY_START, updateCategory);
}

// function* deleteRecordSaga(){
//   yield takeEvery(actionsTypes.DELETE_RESTAURANT_START,deleteRecord);
// }

export function* categorySaga() {
  yield all([fork(getParentCategoryListSaga),fork(getChildCategoryListSaga),fork(addCategorySaga),fork(updateCategorySaga)]); //,fork(deleteRecordSaga)
}
