import userIcon from './userIcon.png';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from "@material-ui/icons/Edit";
import FavoriteIcon from '@material-ui/icons/Favorite';
import LineStyleOutlinedIcon from '@material-ui/icons/LineStyleOutlined';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import MovieCreationOutlinedIcon from '@material-ui/icons/MovieCreationOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
import PublicIcon from '@material-ui/icons/Public';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import downloadIcon from './download.svg';
import sidebarBg from './sidebar-bg.png';
import cmsLogo from './cmsLogo.jpeg';


const Download = GetAppIcon;
const UploadFile = CloudUploadOutlinedIcon;
const NextIcon = NavigateNextIcon;
const SkipIcon = SkipNextIcon;
const BackIcon = ArrowBackIosIcon
const VideoIcon = OndemandVideoOutlinedIcon
const AudioIcon = AudiotrackOutlinedIcon
const CountryIcon = PublicIcon
export {
  userIcon, 
  Download,
  UploadFile,
  ClearIcon,
  NextIcon,
  SkipIcon,
  BackIcon,
  DoneIcon,
  EditIcon,
  LineStyleOutlinedIcon,
  PersonPinIcon,
  MovieCreationOutlinedIcon,
  AccountBalanceWalletOutlinedIcon,
  FavoriteIcon,
  VideoIcon,
  AudioIcon,
  CountryIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  downloadIcon,
  sidebarBg,
  cmsLogo
}
