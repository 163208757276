import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork, takeLatest} from "redux-saga/effects";
import ContentService from '../../services/restaurant.service'
import { act } from "react-dom/test-utils";
import { Restaurant } from "@material-ui/icons";


function* getContentList(action) {
  try {
    if (action.payload) {
      const response = yield ContentService.list();//params
      // console.log('getContentList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        // console.log('response.data userSaga ',response.data?.data);
        yield put(actions.getContentListSuccess(response.data));
      } else {
        yield put(actions.getContentListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getContentListFailure(error));
  }
}

function* managePost(action){
  try {
    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    console.log('managePost contentSagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    const data = new FormData();
    data.append('title',payload.title);
    data.append('logo',payload.logo);
    data.append('detail',payload.detail);
    data.append('status',payload.status);
    data.append('domain',payload.domain);
    data.append('theme',payload.theme);
    response = yield ContentService.managePost(data);
    console.log('managePost response contentSagas============ ',response);
    if (response?.data.statusCode === 201) {
      console.log('response.data contentSagas ',response.data);
      yield put(actions.managePostSuccess(response.data));
    } else {
      yield put(actions.managePostFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.managePostFailure(error));
  }
}
function* updateContent(action){
  try{
    let response; 
    let payload = action.payload;
    const restaurantId = action.payload?._id;
    console.log("restaurantId against which restaurant is going to update",restaurantId,"payload is ->",payload);
    const data = new FormData();
    data.append('title',payload.title);
    data.append('logo',payload.logo);
    data.append('detail',payload.detail);
    data.append('status',payload.status);
    data.append('domain',payload.domain);
    data.append('theme',payload.theme);
    response = yield ContentService.updatePost(
      data,restaurantId);
    console.log('update user response ',response.data);
    if (response.data?.statusCode==200) { 
      yield put(actions.updateContentSuccess(true));
    } else {
      yield put(actions.updateContentFailure(response.data.message));
    }
  }catch(err){
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.updateContentFailure(error));
  }
}

function* manageSeo(action){
  try {
    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    // console.log('managePost contentSagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    response = yield ContentService.manageSeo(payload);
    //console.log('managePost response contentSagas============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data contentSagas ',response.data);
      yield put(actions.manageSeoSuccess(response.data));
    } else {
      yield put(actions.manageSeoFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* getSeoData(action) {
  try {
    if (action.payload) {
      const post_id = action.payload?.post_id;
      const category_id = action.payload?.category_id;
      const _id = action.payload?._id;

      let params = {post_id, category_id,_id};

      const response = yield ContentService.getSeo(params);
      if (response?.status === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.getSeoSuccess(response.data.data[0]));
      } else {
        yield put(actions.getSeoFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    // yield put(actions.getContentListFailure(error));
  }
}

function* getContentListSaga() {
  yield takeLatest(actionsTypes.GET_CONTENT_LIST_START, getContentList);
}

function* managePostSaga(){
  yield takeEvery(actionsTypes.MANAGE_CONTENT_START, managePost);
}

function* updateContentSaga(){
  yield takeEvery(actionsTypes.UPDATE_CONTENT_START, updateContent)
}

function* manageSeoSaga(){
  yield takeEvery(actionsTypes.MANAGE_SEO_START, manageSeo);
}

function* getSeoSaga(){
  yield takeEvery(actionsTypes.GET_SEO_START, getSeoData);
}

export function* restaurantSaga() {
  yield all([fork(getContentListSaga),fork(managePostSaga),fork(updateContentSaga),fork(manageSeoSaga),fork(getSeoSaga),]);
}
