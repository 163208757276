import HttpService from "./http.service";

class RestaurantService extends HttpService {
    list = () => this.get("restaurant/list-restuarants",); 

    managePost = (params) => this.post("restaurant/add-resturant-gallery", params); 

    updatePost = (params,restaurantId) => this.patch(`restaurant/update-resturant/${restaurantId}`,params);

    //manageSeo = (params) => this.post("cms/manage-seo", params);  

    // getSeo = (params) => this.get("cms/get-seo", params); 
 
}

export default new RestaurantService();