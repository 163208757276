import { da } from "date-fns/locale";
import * as actionTypes from "../actionTypes";

export const getMenuList = (data) => {
    return {
        type: actionTypes.GET_MENU_LIST_START,
        payload: data,
    };
};
export const getMenuListSuccess = (data) => {
    return {
        type: actionTypes.GET_MENU_LIST_SUCCESS,
        payload: data,
    };
};

export const getMenuListFailure = (data) => {
    return {
        type: actionTypes.GET_MENU_LIST_FAILURE,
        payload: data,
    };
};

export const addMenu = (data) => {
    return {
        type: actionTypes.ADD_MENU_START,
        payload: data,
    };
};

export const addMenuSuccess = (data) => {
    return {
        type: actionTypes.ADD_MENU_SUCCESS,
        payload: data,
    };
};

export const addMenuFailure = (data) => {
    return {
        type: actionTypes.ADD_MENU_FAILURE,
        payload: data,
    };
};

export const updateMenu = (data) => {
    return {
        type: actionTypes.UPDATE_MENU_START,
        payload: data,
    };
};
export const updateMenuSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_MENU_SUCCESS,
        payload: data,
    };
};

export const updateMenuFailure = (data) => {
    return {
        type: actionTypes.UPDATE_MENU_FAILURE,
        payload: data,
    };
};

export const messageHandler = () => {
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};