import HttpService from "./http.service";

class BranchService extends HttpService {
    list = (params) => this.get("restaurantBranch/list-restuarants-branch", params);

    add = (params) => this.post("restaurantBranch/add-resturant-branch", params);

    update = (params,branchId) => this.patch(`restaurantBranch/update-resturant-branch/${branchId}`,params);
}

export default new BranchService();