import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  contentList: null,
  contentListError: null,
  contentLoading: false,
  content: null,
  contentDataError: null,
  getSeo: null,
  getSeoError: null,
  success: null,
  error: null, 
};
export const restaurantReducer = (state = initialState, action) => {
  // console.log(action.type,' restaurantReducer ',action.payload);
  switch (action.type) {
    case actionTypes.GET_CONTENT_LIST_START:
      return { ...state, loading: true, error:null, contentListError: null, contentList: null,getSeo:null,getSeoError:null };
    case actionTypes.GET_CONTENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        contentListError: null,
        contentList: action.payload,
      };
    };
    case actionTypes.GET_CONTENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        contentListError: action.payload,
        error:action.payload,
        contentList: null,
      };

    case actionTypes.GET_CONTENT_DATA_START:
      return { ...state, contentLoading: true, contentDataError: null, content: null };
    case actionTypes.GET_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        contentLoading: false,
        contentDataError: null,
        content: action.payload,
      };
    case actionTypes.GET_CONTENT_DATA_FAILURE:
      return {
        ...state,
        contentLoading: false,
        contentDataError: action.payload,
        content: null,
      };

    case actionTypes.MANAGE_CONTENT_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.MANAGE_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      }; 

    case actionTypes.UPDATE_CONTENT_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null
      };
    case actionTypes.UPDATE_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      };

    case actionTypes.DELETE_CONTENT_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null
      };
    case actionTypes.DELETE_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
 
    case actionTypes.MESSAGE_HANDLER:
      return {
        ...state,
        loading: false,
        contentListError: action.payload,
        contentDataError: action.payload,
        error: action.payload,
        success: action.payload,
      };
    case actionTypes.MANAGE_SEO_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_SEO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.MANAGE_SEO_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,   
          success: null,
        }; 

    case actionTypes.GET_SEO_START:
      return { ...state, loading: true, getSeoError: null, getSeo: null };
    case actionTypes.GET_SEO_SUCCESS: {
      return {
        ...state,
        loading: false,
        getSeoError: null,
        getSeo: action.payload,
      };
    };
    case actionTypes.GET_SEO_FAILURE:
      return {
        ...state,
        loading: false,
        getSeoError: action.payload,
        getSeo: null,
      };    

    default:
      return { ...state };
  }
};
