import userIcon from "./user-man.svg"; 
import userProfile from './default-welcm-img.png' 
import PaymentIcon from './pngwing.png'; 
import setting from "./setting.svg";
import settingActive from "./setting-active.svg";  
import dashboardIcon from './designers/dashboard.svg';
import dashboardIconActive from './designers/dashboard-active.svg';
import downArrow from './designers/down-arrow.svg'
import burgerIcon from './designers/burger-icon.svg' 
import editor from "./designers/editor.svg"; 
import editorActive from "./designers/editor-active.svg";  
import research from "./designers/research.svg";  
import miscellanous from "./designers/miscellanous.svg";
import miscellanousActive from "./designers/miscellanous-active.svg"; 
import downloadDoc from "./designers/download-doc.svg"; 
import pensionerInfo from "./designers/pensioner-info.svg";  
import restMngIcon from "./designers/transfer-postings.svg"; 
import restMngIconActive from "./designers/transfer-postings-active.svg";  
import userMngIcon from "./designers/gm-power-transfer.svg";
import userMngIconActive from "./designers/gm-power-transfer-active.svg"; 
import logoWhite from './white-logo.png'
import perfettoLogo from './ropay.png'

import logoBlack from './black-logo.png'
import subTabIcon from './SubTabIcon.svg'
import activeSubTabIcon from './ActiveSubTabIcon.svg'
import themeImg from './themeImg.png';

const menuIcon = burgerIcon;

export {
  dashboardIcon,
  dashboardIconActive,
  userIcon, 
  menuIcon,
  userProfile,
  downArrow,
  PaymentIcon, 
  setting,
  settingActive, 
  editor, editorActive,   research, miscellanous,miscellanousActive,downloadDoc,
  pensionerInfo,   restMngIcon, restMngIconActive, userMngIcon,userMngIconActive,logoWhite,logoBlack,subTabIcon,
  activeSubTabIcon,perfettoLogo,themeImg
}
