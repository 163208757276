import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  fileList:null,
  tableList: null,
  tableListError: null,
  tableLoading: false,
  table: null,
  tableDataError: null,
  success: null,
  error: null, 
};
export const tableReducer = (state = initialState, action) => {
  // console.log(action.type,' galleryReducer ',action.payload,'state',state);
  switch (action.type) {

    case actionTypes.GET_TABLE_LIST_START:
      return { ...state, loading: true, tableListError: null, tableList: null,success:null};
    case actionTypes.GET_TABLE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        tableListError: null,
        tableList: action.payload,
      };
    };
    case actionTypes.GET_TABLE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        tableListError: action.payload,
        tableList: null,
      };

 /*
    case actionTypes.GET_GALLERY_DATA_START:
      return { ...state, galleryLoading: true, galleryDataError: null, gallery: null };
    case actionTypes.GET_GALLERY_DATA_SUCCESS:
      return {
        ...state,
        galleryLoading: false,
        galleryDataError: null,
        gallery: action.payload,
      };
    case actionTypes.GET_GALLERY_DATA_FAILURE:
      return {
        ...state,
        galleryLoading: false,
        galleryDataError: action.payload,
        gallery: null,
      };
    */
    case actionTypes.MANAGE_TABLE_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.MANAGE_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      }; 
    /*  
    case actionTypes.DELETE_GALLERY_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: 'Delete Successfully',
        error: null
      };
    case actionTypes.DELETE_GALLERY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    */  
    case actionTypes.MESSAGE_HANDLER:
      return {
        ...state,
        loading: false,
        galleryListError: action.payload,
        galleryDataError: action.payload,
        error: action.payload,
        success: action.payload,
      };
    /*  
    case actionTypes.MANAGE_VIDEO_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.MANAGE_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      }; 
    */
   /*  
    case actionTypes.MANAGE_FILE_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.MANAGE_FILE_FAILURE:

    case actionTypes.GET_FILE_LIST_START:
      return { ...state, loading: true, fileListError: null, fileList: null,success:null};
    case actionTypes.GET_FILE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        fileListError: null,
        fileList: action.payload,
      };
    };
    case actionTypes.GET_FILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        fileListError: action.payload,
        fileList: null,
      };

    case actionTypes.DELETE_FILE_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.DELETE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: 'Delete Successfully',
        error: null
      };
    case actionTypes.DELETE_FILE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
          success: null,
        };
    */    


    default:
      return { ...state };
  }
};
