import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork, takeLatest} from "redux-saga/effects";
import MenuService from '../../services/menu.service'
import { act } from "react-dom/test-utils";
import { Restaurant } from "@material-ui/icons";


function* getMenuList(action) {
  try {
    if (action.payload) {
      const response = yield MenuService.list({category_id: action.payload.category_id, branch_id: action.payload.branch_id});//params
      console.log('getMenuList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        console.log('response.data userSaga ',response.data?.data);
        yield put(actions.getMenuListSuccess(response.data));
      } else {
        yield put(actions.getMenuListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getMenuListFailure(error));
  }
}

function* getMenuListSaga() {
  yield takeEvery(actionsTypes.GET_MENU_LIST_START, getMenuList);
}

function* addMenu(action) {
  try {
    if (action.payload) {
      let payload = action.payload
      const data = new FormData();
      data.append('title',payload.title);
      data.append('file',payload.file);
      data.append('order',payload.order);
      data.append('price',payload.price);
      data.append('serving_time',payload.serving_time);
      data.append('is_addon',payload.is_addon);
      data.append('is_drink',payload.is_drink);
      data.append('status',payload.status);
      data.append('category_id',payload.category_id);
      data.append('restaurant_branch_id',payload.restaurant_branch_id);
      const response = yield MenuService.add(data);//params
      if (response?.data.statusCode === 201) {
        yield put(actions.addMenuSuccess(response.data));
      } else {
        yield put(actions.addMenuFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.addMenuFailure(error));
  }
}

function* addMenuSaga() {
  yield takeEvery(actionsTypes.ADD_MENU_START, addMenu);
}

function* updateMenu(action) {
  try {
    if (action.payload) {
      let payload = action.payload
      const data = new FormData();
      data.append('title',payload.title);
      data.append('file',payload.file);
      data.append('order',payload.order);
      data.append('price',payload.price);
      data.append('serving_time',payload.serving_time);
      data.append('is_addon',payload.is_addon);
      data.append('is_drink',payload.is_drink);
      data.append('status',payload.status);
      data.append('category_id',payload.category_id);
      const productId = action.payload?._id;
      const response = yield MenuService.update(data,productId);//params
      if (response?.data.statusCode === 200) {
        yield put(actions.updateMenuSuccess(response.data));
      } else {
        yield put(actions.updateMenuFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.updateMenuFailure(error));
  }
}

function* updateMenuSaga() {
  yield takeEvery(actionsTypes.UPDATE_MENU_START, updateMenu);
}

export function* menuSaga() {
  yield all([fork(getMenuListSaga), fork(addMenuSaga), fork(updateMenuSaga)]);
}
