import axios from "axios";
import {API_URL_RESTAURANT,API_URL_ORDER} from "../environment";

if (localStorage.getItem("token")) {
  axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token");
}

if (localStorage.getItem("user")) {
  let user = localStorage.getItem("user"); 
  axios.defaults.headers.common["user"] = user;
}

export default class HttpService {
  static setToken = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer "+token;
  };
  static setUser = (user) => { 
    axios.defaults.headers.common["user"] = user;
  };

  static removeUser = () => {
    axios.defaults.headers.common["user"] = '';
    axios.defaults.headers.common["Authorization"]=null;
  };
  //API_URL_RESTAURANT 
  get = (url, params) => axios.get(`${API_URL_RESTAURANT}/${url}`, {params});

  post = (url, data) => axios.post(`${API_URL_RESTAURANT}/${url}`, data);

  put = (url, data, params) => axios.put(`${API_URL_RESTAURANT}/${url}`, data, {params});

  patch = (url,  params) => axios.patch(`${API_URL_RESTAURANT}/${url}`,  params);

  delete = (url, params) => axios.delete(`${API_URL_RESTAURANT}/${url}`, {params});

  gets = (url, params) => axios.get(`${API_URL_RESTAURANT}/${url}`, {params});

  //API_URL_ORDER

  postOrder = (url, data) => axios.post(`${API_URL_ORDER}/${url}`, data);

  getOrder = (url,data) =>axios.get(`${API_URL_ORDER}/${url}`,{data})

  patchOrder = (url,  params) => axios.patch(`${API_URL_ORDER}/${url}`,  params);




  // patch = (url, params) => axios.patch(`${API_URL}/${url}`, {params});

  //addressget = (params) => axios.get(`https://api.ideal-postcodes.co.uk/v1/autocomplete/addresses?api_key=ak_jc635mjv12swIsWCiEJWOAiDG0W84&query=${params}`);
}
