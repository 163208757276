import React from 'react'; 
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'; 
import Typography from '@material-ui/core/Typography';
import * as actions from '../../../store/actions'
import {connect} from "react-redux";
import { compose } from 'redux';  
import './style.scss'  
import {withStyles} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";  
import { useStyles } from './styles'; 
import {Alert, Loader} from '../../../components' 
import SimpleReactValidator from 'simple-react-validator'; 
import {getUserDataFromLocalStorage} from "../../../services/utils";
import { cmsLogo } from "../../../assets/images/images";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'; 
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import UseAnalyticsEventTracker from '../../../components/googleAnalytics';

class SignIn extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {email: '', password: ''}; 
    this.handleSubmit = this.handleSubmit.bind(this); 
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => (
        <div className="field-error">
          <Alert type={'error'} message={message}/> 
        </div>
      )
    });
  }

  handleClickShowPassword = () => this.setState({showPassword : !this.state.showPassword});

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleEmailChanged(event) {
    this.setState({email: event.target.value});
  }

  handlePasswordChanged(event) {
    this.setState({password: event.target.value});
  }

  handleSubmit(event) {   
    event.preventDefault(); 
    
    let isError=false;
    if (!this.validator.allValid()) //!this.validator.fieldValid('email') || !this.validator.fieldValid('password')
      isError=true; 
  
    if(!isError){
      UseAnalyticsEventTracker('Login')
      this.props.onLoginHandler({email:this.state.email,password:this.state.password}); 
      }
    else
      this.validator.showMessages();
  } 
  
  componentDidMount(){
    console.log('componentDidMount '); 
    document.body.classList.add('background');
  }

  componentWillUnmount(){
    console.log('componentWillUnmount'); 
  }

  render() { 
    const { classes } = this.props;
 
    if(this.props.loggedInSuccess ){ 
      getUserDataFromLocalStorage()?this.props.history.push("/dashboard"):this.props.setLoginFalse();
      this.props.errorHandlerSuccess();
    }

    return (
      
      
      
      <div className='main-wrapper'>
      {(this.props.error) &&
        <Alert type={'error'} message={this.props.error}/>
      }

      {(this.props.success) &&
        <Alert type={'success'} message={this.props.success}/>
      } 
      <div className='login-overlay'></div>
      <div className='login-wrapper'>
        <Container fixed className='login-wrapper-container'>
          <Grid container spacing={4}>
            <Grid item lg={7} className="login-right">
              <div className='login-heading'>
                <div>
                  <img className='logo' src={cmsLogo} alt="logo" />
                </div>
                <div>
                  <Typography className='login-title-h'>
                    WELCOME TO CONTENT
                    <br className='login-title-br' />
                    <span>MANAGEMENT SYSTEM</span> 
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item lg={5} className="login-left">
              <div>
                <div className='login-form'>  
                  <div className={classes.loginForm}>
                    <h2 id="simple-modal-title">Log in to your Account</h2>
                    <p>Enter your credentials to continue.</p>
                    <FormControl className={`${classes.fullWidth} text-field-email`}>
                        <TextField
                          variant="outlined"
                          type='email'
                          value={this.state.email}
                          required
                          fullWidth
                          defaultValue={''} 
                          placeholder={'Email'}
                          onChange={this.handleEmailChanged.bind(this)}
                        />
                        <div className='left-icon'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
                            <path opacity="0.5" d="M6.54446 0.954102C4.61775 0.954102 3.05025 2.5216 3.05025 4.44831C3.05025 6.37502 4.61775 7.94252 6.54446 7.94252C8.47116 7.94252 10.0387 6.37502 10.0387 4.44831C10.0387 2.5216 8.47116 0.954102 6.54446 0.954102ZM6.54446 6.54483C5.38857 6.54483 4.44793 5.60419 4.44793 4.44831C4.44793 3.29243 5.38857 2.35178 6.54446 2.35178C7.70034 2.35178 8.64098 3.29243 8.64098 4.44831C8.64098 5.60419 7.70034 6.54483 6.54446 6.54483ZM12.834 14.2321V13.5332C12.834 10.8364 10.639 8.64136 7.94214 8.64136H5.14677C2.44925 8.64136 0.254883 10.8364 0.254883 13.5332V14.2321H1.65257V13.5332C1.65257 11.6065 3.22007 10.039 5.14677 10.039H7.94214C9.86885 10.039 11.4363 11.6065 11.4363 13.5332V14.2321H12.834Z" fill="#252D40"/>
                          </svg>
                        </div>
                        {this.validator.message(
                          'email',
                          this.state.email,
                          'required|email'
                        )}
                    </FormControl>
                    <FormControl className={`${classes.fullWidth} text-field-password`}>
                      <TextField
                          variant="outlined"
                          type={this.state.showPassword ? 'text' : 'password'}
                          value={this.state.password}
                          required
                          fullWidth
                          defaultValue={''} 
                          placeholder={'Password'}
                          onChange={this.handlePasswordChanged.bind(this)}
                        />
                        <div className='left-icon'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                            <path opacity="0.5" d="M6.54435 0C4.61764 0 3.05014 1.5675 3.05014 3.49421V4.89189H2.3513C1.58047 4.89189 0.953613 5.51875 0.953613 6.28957V12.5791C0.953613 13.35 1.58047 13.9768 2.3513 13.9768H10.7374C11.5082 13.9768 12.1351 13.35 12.1351 12.5791V6.28957C12.1351 5.51875 11.5082 4.89189 10.7374 4.89189H10.0386V3.49421C10.0386 1.5675 8.47105 0 6.54435 0ZM4.44782 3.49421C4.44782 2.33832 5.38846 1.39768 6.54435 1.39768C7.70023 1.39768 8.64087 2.33832 8.64087 3.49421V4.89189H4.44782V3.49421ZM10.7388 12.5791H7.24319V10.9872C7.659 10.7447 7.94203 10.2988 7.94203 9.78378C7.94203 9.01296 7.31517 8.3861 6.54435 8.3861C5.77352 8.3861 5.14666 9.01296 5.14666 9.78378C5.14666 10.2981 5.42969 10.7447 5.8455 10.9872V12.5791H2.3513V6.28957H10.7374L10.7388 12.5791Z" fill="#252D40"/>
                          </svg>
                        </div>
                        <div className='right-icon'>
                          <IconButton
                            style={{padding:0}}
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword.bind(this)}
                            onMouseDown={this.handleMouseDownPassword.bind(this)}
                            edge="end"
                          >
                            {this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </div>
                        {this.validator.message(
                            'password',
                            this.state.password,
                            'required'
                        )}
                    </FormControl>
                    <FormControlLabel sx={{fontWeight: 400, fontSize: 13, lineHeight: 20, color: '#252D40', opacity: 0.6, marginBottom:1}} value="female" control={<Radio size='small' />} label={<Typography className="radio-label">Remember Me</Typography>} />
                    <Button variant="contained"  onClick={this.handleSubmit} className={`login-button ${classes.fullWidth}`}
                      >LOGIN TO CONTINUE
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
    );
  }
}

const mapStateToProps = ({authReducer}) => { 
  const {loading,error,success,loggedInSuccess} = authReducer;  
  // console.log('error ',error,' loading ',loading);
  // console.log('success ',success);
  // console.log('loggedInSuccess ',loggedInSuccess);
  return {loading,error,success,loggedInSuccess};
};
const mapDispatchToProps = (dispatch) => {
  return { 
    onLoginHandler: (data) => dispatch(actions.loginStart(data)),
    errorHandlerSuccess: () => dispatch(actions.loginErrorHandlerSuccess()), 
    setLoginFalse: () => dispatch(actions.logout())
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps, // or put null here if you do not have actions to dispatch
  ),
  withStyles(useStyles),
)(SignIn); 