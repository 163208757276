import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  parentCategoryList: null,
  parentCategoryListError: null,
  parentCategoryLoading: false,
  parentCategory: null,
  parentCategoryDataError: null,
  childCategoryList: null,
  childCategoryListError: null,
  childCategoryLoading: false,
  childCategory: null,
  childCategoryDataError: null,
  getSeo:null,
  getSeoError:null,
  success: null,
  error: null, 
};
export const categoryReducer = (state = initialState, action) => { 
  switch (action.type) {
    case actionTypes.GET_PARENT_CATEGORY_LIST_START:
      return { ...state, loading: true, parentCategoryListError: null, parentCategoryList: null, getSeo:null,getSeoError:null};
    case actionTypes.GET_PARENT_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        parentCategoryListError: null,
        parentCategoryList: action.payload,
      };
    };
    case actionTypes.GET_PARENT_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        parentCategoryListError: action.payload,
        parentCategoryList: null,
      };
    case actionTypes.GET_CHILD_CATEGORY_LIST_START:
      return { ...state, loading: true, childCategoryListError: null, childCategoryList: null,getSeo:null,getSeoError:null};
    case actionTypes.GET_CHILD_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        childCategoryListError: null,
        childCategoryList: action.payload,
      };
    };
    case actionTypes.GET_CHILD_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        childCategoryListError: action.payload,
        childCategoryList: null,
      };
    case actionTypes.ADD_CATEGORY_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.ADD_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      }; 
    case actionTypes.UPDATE_CATEGORY_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      }; 

    // case actionTypes.DELETE_RESTAURANT_START:
    //   return { ...state, loading: true, success: null, error: null };
    // case actionTypes.DELETE_RESTAURANT_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     success: action.payload.message,
    //     successMessage: "Record deleted successfully",
    //     error: null
    //   };
    // case actionTypes.DELETE_RESTAURANT_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,   
    //     success: null,
    //   }; 




    
    case actionTypes.MESSAGE_HANDLER:
      return {
        ...state,
        loading: false,
        parentCategoryListError: action.payload,
        parentCategoryDataError: action.payload,
        childCategoryListError: action.payload,
        childCategoryDataError: action.payload,
        error: action.payload,
        success: action.payload,
      };
    default:
      return { ...state };
  }
};
