import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork, takeLatest} from "redux-saga/effects";
import OrderService from '../../services/order.service'
import { act } from "react-dom/test-utils";
import { Restaurant } from "@material-ui/icons";


function* getOrderList(action) {
  try {
    if (action.payload) {
      const response = yield OrderService.list({restaurant_branch_id: action.payload.branch_id});//params
      console.log('getOrderList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        console.log('response.data orderSaga ',response.data);
        yield put(actions.getOrderListSuccess(response.data));
      } else {
        yield put(actions.getOrderListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getOrderListFailure(error));
  }
}

function* getOrderListSaga() {
  yield takeEvery(actionsTypes.GET_ORDER_LIST_START, getOrderList);
}

function* addOrder(action) {
  try {
    if (action.payload) {
      let payload = action.payload
      const data = new FormData();
      data.append('title',payload.title);
      data.append('file',payload.file);
      data.append('order',payload.order);
      data.append('price',payload.price);
      let { serving_time } = payload;
      for (var i = 0; i < serving_time.length; i++) {
        data.append('serving_time',serving_time[i]);
      }
      data.append('is_addon',payload.is_addon);
      data.append('is_drink',payload.is_drink);
      data.append('category_id',payload.category_id);
      data.append('restaurant_branch_id',payload.restaurant_branch_id);
      const response = yield OrderService.add(data);//params
      if (response?.data.statusCode === 201) {
        yield put(actions.addOrderSuccess(response.data));
      } else {
        yield put(actions.addOrderFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.addOrderFailure(error));
  }
}

function* addOrderSaga() {
  yield takeEvery(actionsTypes.ADD_ORDER_START, addOrder);
}

function* updateOrder(action) {
  try {
    if (action.payload) {
      const delivery_status = action.payload?.delivery_status;
      const orderId = action.payload?._id;
      let params = {orderId, delivery_status};
      const response = yield OrderService.update({orderId: orderId},params);//params
      if (response?.data.statusCode === 200) {
        yield put(actions.updateOrderSuccess(response.data));
      } else {
        yield put(actions.updateOrderFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.updateOrderFailure(error));
  }
}

function* updateOrderSaga() {
  yield takeEvery(actionsTypes.UPDATE_ORDER_START, updateOrder);
}

export function* orderSaga() {
  yield all([fork(getOrderListSaga), fork(addOrderSaga), fork(updateOrderSaga)]);
}
