import { da } from "date-fns/locale";
import * as actionTypes from "../actionTypes";

export const getBranchList = (data) => {
    return {
        type: actionTypes.GET_BRANCH_LIST_START,
        payload: data,
    };
};
export const getBranchListSuccess = (data) => {
    console.log("data in action of getBranchListSuccess",data.data);
    return {
        type: actionTypes.GET_BRANCH_LIST_SUCCESS,
        payload: data,
    };
};

export const getBranchListFailure = (data) => {
    return {
        type: actionTypes.GET_BRANCH_LIST_FAILURE,
        payload: data,
    };
};

export const addBranch = (data) => {
    return {
        type: actionTypes.ADD_BRANCH_START,
        payload: data,
    };
};

export const addBranchSuccess = (data) => {
    return {
        type: actionTypes.ADD_BRANCH_SUCCESS,
        payload: data,
    };
};

export const addBranchFailure = (data) => {
    return {
        type: actionTypes.ADD_BRANCH_FAILURE,
        payload: data,
    };
};

export const updateBranch = (data) => {
    return {
        type: actionTypes.UPDATE_BRANCH_START,
        payload: data,
    };
};
export const updateBranchSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_BRANCH_SUCCESS,
        payload: data,
    };
};

export const updateBranchFailure = (data) => {
    return {
        type: actionTypes.UPDATE_BRANCH_FAILURE,
        payload: data,
    };
};

export const messageHandler = () => {
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};