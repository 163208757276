import React, { useEffect, useState } from 'react';
import Typography from "@material-ui/core/Typography";
import { CustomSeparator, Loader } from "./index";
import { helpHandler, isLoggedIn } from "../store/actions";
import './styles.css'
import { useDispatch, useSelector } from "react-redux";
import { dashboardPage } from "../services/userManual"; 
import UseAnalyticsEventTracker from '../components/googleAnalytics';

function Dashboard(props) {
  const dispatch = useDispatch(); 
  const [delay, setDelay] = useState(false);
  const helpClick = () => {
    dispatch(helpHandler({ open: true, data: dashboardPage }))
  }

  useEffect(() => {
    UseAnalyticsEventTracker('Home');
  }, [])


  const [breadCrumbsList, setBreadCrumbsList] = React.useState([
    { text: 'Dashboard', url: '' },
  ]);

  useEffect(() => {
    document.body.classList.remove('background');
  })

  useEffect(() => { 
    setTimeout(() => {
      setDelay(true);
    }, 1500);
  }, [])

  const showGraph = () => {
    return (
      <>
        <div>
          <h2>Restaurant</h2>
        </div>
      </> 
    )
  }

  return (
    <>
      <div className='bg-dash-text'>
        <Typography className='dash-text'>Dashboard</Typography>
      </div>
      <div className="driver-main-page">
        
        <div className="datatable-container1">
          <div className='table-title-container'>
            <Typography variant='h4' className='table-title'>Welcome {props.location?.state?.currentUser ? props.location?.state?.currentUser?.name:''}! </Typography> 
          </div>
          <div className='full-width align-items-center mt-3'>
           
            <div>
              <h2>Restaurant</h2>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
export default Dashboard;
