// Users Action Types

export const GET_USERS_LIST_START = "GET_USERS_LIST_START";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";

export const GET_WAITERS_LIST_START ="GET_WAITERS_LIST_START";
export const GET_WAITERS_LIST_SUCCESS = "GET_WAITERS_LIST_SUCCESS";
export const GET_WAITERS_LIST_FAILURE = "GET_WAITERS_LIST_FAILURE";

export const SET_USER_SERVICES_LIST = "USER_SERVICES_LIST";

// export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
// export const GET_USER_DATA_FAILURE = "GET_USER_DATA_ERROR";

export const GET_USER_DATA_START = "GET_USER_DATA_START";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_ERROR";

export const MESSAGE_HANDLER = "MESSAGE_HANDLER"; 

export const ADD_NEW_USER_START = "ADD_NEW_USER_START";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "ADD_NEW_USER_FAILURE";
 

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const LIST_USER_PERMISSION_START = "LIST_USER_PERMISSION_START";
export const LIST_USER_PERMISSION_SUCCESS = "LIST_USER_PERMISSION_SUCCESS";
export const LIST_USER_PERMISSION_FAILURE = "LIST_USER_PERMISSION_FAILURE";

export const MANAGE_USER_PERMISSION_START = "MANAGE_USER_PERMISSION_START";
export const MANAGE_USER_PERMISSION_SUCCESS = "MANAGE_USER_PERMISSION_SUCCESS";
export const MANAGE_USER_PERMISSION_FAILURE = "MANAGE_User_PERMISSION_FAILURE"

export const LIST_MODULE_START = "LIST_MODULE_START";
export const LIST_MODULE_SUCCESS = "LIST_MODULE_SUCCESS";
export const LIST_MODULE_FAILURE = "LIST_MODULE_FAILURE";

export const LIST_LOGIN_HISTORY_START = "LIST_LOGIN_HISTORY_START";
export const LIST_LOGIN_HISTORY_SUCCESS = "LIST_LOGIN_HISTORY_SUCCESS";
export const LIST_LOGIN_HISTORY_FAILURE = "LIST_LOGIN_HISTORY_FAILURE"

///////////////////////////////////////////////////////// 
// MANAGE CONFIG ACTION TYPES

export const GET_PARENT_CATEGORY_LIST_START = "GET_PARENT_CATEGORY_LIST_START";
export const GET_PARENT_CATEGORY_LIST_SUCCESS = "GET_PARENT_CATEGORY_LIST_SUCCESS";
export const GET_PARENT_CATEGORY_LIST_FAILURE = "GET_PARENT_CATEGORY_LIST_FAILURE";

///////////////////////////////////////////

// Category Action Types

export const MANAGE_CONFIG_START = "MANAGE_CONFIG_START";
export const MANAGE_CONFIG_SUCCESS = "MANAGE_CONFIG_SUCCESS";
export const MANAGE_CONFIG_FAILURE = "MANAGE_CONFIG_FAILURE";

export const GET_CONFIG_START = "GET_CONFIG_START";
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";

export const GET_CHILD_CATEGORY_LIST_START = "GET_CHILD_CATEGORY_LIST_START";
export const GET_CHILD_CATEGORY_LIST_SUCCESS = "GET_CHILD_CATEGORY_LIST_SUCCESS";
export const GET_CHILD_CATEGORY_LIST_FAILURE = "GET_CHILD_CATEGORY_LIST_FAILURE";

export const ADD_CATEGORY_START = "ADD_CATEGORY_START";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_START = "UPDATE_CATEGORY_START";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

/////////////////////////////////////////////////////////

// Content Action Types

export const GET_CONTENT_LIST_START = "GET_CONTENT_LIST_START";
export const GET_CONTENT_LIST_SUCCESS = "GET_CONTENT_LIST_SUCCESS";
export const GET_CONTENT_LIST_FAILURE = "GET_CONTENT_LIST_FAILURE";

export const GET_CONTENT_DATA_START = "GET_CONTENT_DATA_START";
export const GET_CONTENT_DATA_SUCCESS = "GET_CONTENT_DATA_SUCCESS";
export const GET_CONTENT_DATA_FAILURE = "GET_CONTENT_DATA_ERROR";

export const MANAGE_CONTENT_START = "MANAGE_CONTENT_START";
export const MANAGE_CONTENT_SUCCESS = "MANAGE_CONTENT_SUCCESS";
export const MANAGE_CONTENT_FAILURE = "MANAGE_CONTENT_FAILURE";

export const ADD_NEW_CONTENT_START = "ADD_NEW_CONTENT_START";
export const ADD_NEW_CONTENT_SUCCESS = "ADD_NEW_CONTENT_SUCCESS";
export const ADD_NEW_CONTENT_FAILURE = "ADD_NEW_CONTENT_FAILURE";
 
export const UPDATE_CONTENT_START = "UPDATE_CONTENT_START";
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS";
export const UPDATE_CONTENT_FAILURE = "UPDATE_CONTENT_FAILURE";

export const DELETE_CONTENT_START = "DELETE_CONTENT_START";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
export const DELETE_CONTENT_FAILURE = "DELETE_CONTENT_FAILURE";

// Branch Action Types

export const GET_BRANCH_LIST_START = "GET_BRANCH_LIST_START";
export const GET_BRANCH_LIST_SUCCESS = "GET_BRANCH_LIST_SUCCESS";
export const GET_BRANCH_LIST_FAILURE = "GET_BRANCH_LIST_FAILURE";

export const ADD_BRANCH_START = "ADD_BRANCH_START";
export const ADD_BRANCH_SUCCESS = "ADD_BRANCH_SUCCESS";
export const ADD_BRANCH_FAILURE = "ADD_BRANCH_FAILURE";

export const UPDATE_BRANCH_START = "UPDATE_BRANCH_START";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILURE = "UPDATE_BRANCH_FAILURE";

// Menu Action Types

export const GET_MENU_LIST_START = "GET_MENU_LIST_START";
export const GET_MENU_LIST_SUCCESS = "GET_MENU_LIST_SUCCESS";
export const GET_MENU_LIST_FAILURE = "GET_MENU_LIST_FAILURE";

export const ADD_MENU_START = "ADD_MENU_START";
export const ADD_MENU_SUCCESS = "ADD_MENU_SUCCESS";
export const ADD_MENU_FAILURE = "ADD_MENU_FAILURE";

export const UPDATE_MENU_START = "UPDATE_MENU_START";
export const UPDATE_MENU_SUCCESS = "UPDATE_MENU_SUCCESS";
export const UPDATE_MENU_FAILURE = "UPDATE_MENU_FAILURE";

// Order Action Types

export const GET_ORDER_LIST_START = "GET_ORDER_LIST_START";
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS";
export const GET_ORDER_LIST_FAILURE = "GET_ORDER_LIST_FAILURE";

export const ADD_ORDER_START = "ADD_ORDER_START";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILURE = "ADD_ORDER_FAILURE";

export const UPDATE_ORDER_START = "UPDATE_ORDER_START";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

/////////////////////////////File Uplaod Action types

export const MANAGE_FILE_START = "MANAGE_FILE_START";
export const MANAGE_FILE_SUCCESS = "MANAGE_FILE_SUCCESS";
export const MANAGE_FILE_FAILURE = "MANAGE_FILE_FAILURE";

export const GET_FILE_LIST_START = "GET_FILE_LIST_START";
export const GET_FILE_LIST_SUCCESS = "GET_FILE_LIST_SUCCESS";
export const GET_FILE_LIST_FAILURE = "GET_FILE_LIST_FAILURE";

export const DELETE_FILE_START = "DELETE_FILE_START";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

///////////////////////////////////////////////////////SEO ACTION TYPES

export const MANAGE_SEO_START = "MANAGE_SEO_START";
export const MANAGE_SEO_SUCCESS = "MANAGE_SEO_SUCCESS";
export const MANAGE_SEO_FAILURE = "MANAGE_SEO_FAILURE";


export const GET_SEO_START = "GET_SEO_START";
export const GET_SEO_SUCCESS = "GET_SEO_SUCCESS";
export const GET_SEO_FAILURE = "GET_SEO_LIST_FAILURE";

///////////////////////////////////////////////////////////////

//TABLE Action Types
export const GET_TABLE_LIST_START = "GET_TABLE_LIST_START";
export const GET_TABLE_LIST_SUCCESS = "GET_TABLE_LIST_SUCCESS";
export const GET_TABLE_LIST_FAILURE = "GET_TABLE_LIST_FAILURE"; //GET_GALLERY_LIST_FAILURE

// export const GET_GALLERY_LIST_START = "GET_GALLERY_LIST_START";// need to remove
// export const GET_GALLERY_LIST_SUCCESS = "GET_GALLERY_LIST_SUCCESS";
// export const GET_GALLERY_LIST_FAILURE = "GET_GALLERY_LIST_FAILURE";

export const MANAGE_TABLE_START = "MANAGE_TABLE_START"; //MANAGE_GALLERY_START
export const MANAGE_TABLE_SUCCESS = "MANAGE_TABLE_SUCCESS";
export const MANAGE_TABLE_FAILURE = "MANAGE_TABLE_FAILURE";

// export const MANAGE_GALLERY_START = "MANAGE_GALLERY_START";// need to remove
// export const MANAGE_GALLERY_SUCCESS = "MANAGE_GALLERY_SUCCESS";
// export const MANAGE_GALLERY_FAILURE = "MANAGE_GALLERY_FAILURE";

// export const GET_GALLERY_DATA_START = "GET_GALLERY_DATA_START";
// export const GET_GALLERY_DATA_SUCCESS = "GET_GALLERY_DATA_SUCCESS";
// export const GET_GALLERY_DATA_FAILURE = "GET_GALLERY_DATA_ERROR";

// export const ADD_NEW_GALLERY_START = "ADD_NEW_GALLERY_START";
// export const ADD_NEW_GALLERY_SUCCESS = "ADD_NEW_GALLERY_SUCCESS";
// export const ADD_NEW_GALLERY_FAILURE = "ADD_NEW_GALLERY_FAILURE";
 
// export const UPDATE_GALLERY_START = "UPDATE_GALLERY_START";
// export const UPDATE_GALLERY_SUCCESS = "UPDATE_GALLERY_SUCCESS";
// export const UPDATE_GALLERY_FAILURE = "UPDATE_GALLERY_FAILURE";

// export const DELETE_GALLERY_START = "DELETE_GALLERY_START";
// export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS";
// export const DELETE_GALLERY_FAILURE = "DELETE_GALLERY_FAILURE";

// export const MANAGE_VIDEO_START = "MANAGE_VIDEO_START";
// export const MANAGE_VIDEO_SUCCESS = "MANAGE_VIDEO_SUCCESS";
// export const MANAGE_VIDEO_FAILURE = "MANAGE_VIDEO_FAILURE";

//////////////////////////////////////////////////////////////////

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LIST_QUERY_SUCCESS = "LIST_QUERY_SUCCESS"
export const LIST_QUERY_START = "LIST_QUERY_START"
export const LIST_QUERY_FAILURE = "LIST_QUERY_FAILURE"
// export const OTP_GEN = "OTP_GEN";
// export const OTPGEN_SUCCESS = "OTPGEN_SUCCESS";
// export const OTPGEN_FAILURE = "OTPGEN_FAILURE";

export const LOGIN_ERROR_HANDLER_SUCCESS = "LOGIN_ERROR_HANDLER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
export const DASHBOARD_ERROR_HANDLER_SUCCESS = "DASHBOARD_ERROR_HANDLER_SUCCESS";

export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const HELP_HANDLER = "HELP_HANDLER";

//DELETE RESTAURANT RECORD 
export const DELETE_RESTAURANT_START = "DELETE_RESTAURANT_START";
export const DELETE_RESTAURANT_SUCCESS = "DELETE_RESTAURANT_SUCCESS";
export const DELETE_RESTAURANT_FAILURE = "DELETE_RESTAURANT_FAILURE";