import HttpService from "./http.service";

class UserService extends HttpService {
//  list = (params) => this.get(`users/users-list`, params);

    list = (role_id,params) => this.get(`users/users-list/${role_id}`,params);

    //editUser = (data,params) => this.patch(`users/update-user-infomation`, data);

    editUser =(data,user_id) =>this.patch(`users/admin-update-user/${user_id}`,data);

    listUserPermission = (params) => this.get(`public/auth/userPermission`, params);

    manageUserPermission = (data,params) => this.post(`public/auth/manageUserPermission/${params}`, data);
    
    listLoginHistory = (params) => this.get("admin/user/listLoginHistory", params);
    
    listModules = (params) => this.get(`public/auth/listModule`, params);
    
    // create = (data) => this.post(`suppliers/job`, data);

    // createOrder = (data) => this.post(`orderRequest`, data); 
    // show = (id) => this.get(`suppliers/job/${id}`);

    // update = (id, data) => this.put(`suppliers/job/${id}`, data);

    // invoice = (data) => this.post(`downloadJobInvoice`, data);

    // cancel = (data) => this.post(`cancelAppointment`, data);

    // createExchange = (data) => this.post(`exchangeSkip`, data);

    // requestCollection = (data) => this.post(`requestCollection`, data);

    // addWaste = (id, data) => this.put(`suppliers/job/${id}/waste`, data);

    // getWasteTypes = (params) => this.get(`suppliers/waste-types`, params);
}

export default new UserService();
