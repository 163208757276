import OrderHttpService from "./order-http.service";

class OrderService extends OrderHttpService {
    list = (params) => this.post("order/getAllOrderDetails", params);

    add = (params) => this.post("order/addDetails", params);

    update = (params,data) => this.put(`order/updateDetailsByAdmin`, data, params);
}

export default new OrderService();