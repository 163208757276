import OrderHttpService from "./order-http.service";

class CategoryService extends OrderHttpService {
    listCategories = (params) => this.get("category/categories-list",params);//cms/list-parent-categories restaurant/list-restuarants
    // manageCategory = (params) => this.post("restaurant/add-resturant-gallery", params);//cms/manage-category
    addCategory = (params) => this.post(`category/add-category`, params);
    updateCategory = (params, categoryId) => this.patch(`category/update-restaurant-category/${categoryId}`, params);
    deleteRestaurantRecord = (restaurant_id) => this.delete(`restaurant/delete-resturant/${restaurant_id}`);
}

export default new CategoryService();