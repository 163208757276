import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork, takeLatest} from "redux-saga/effects";
import BranchService from '../../services/branch.service'
import { act } from "react-dom/test-utils";
import { Restaurant } from "@material-ui/icons";


function* getBranchList(action) {
  try {
    if (action.payload) {
      const response = yield BranchService.list({restaurant_id: action.payload.restaurant_id});//params
      console.log('getBranchList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        console.log('response.data userSaga ',response.data?.data);
        yield put(actions.getBranchListSuccess(response.data));
      } else {
        yield put(actions.getBranchListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getBranchListFailure(error));
  }
}

function* getBranchListSaga() {
  yield takeEvery(actionsTypes.GET_BRANCH_LIST_START, getBranchList);
}

function* addBranch(action) {
  try {
    if (action.payload) {
      const response = yield BranchService.add(action.payload);//params
      if (response?.data.statusCode === 201) {
        yield put(actions.addBranchSuccess(response.data));
      } else {
        yield put(actions.addBranchFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.addBranchFailure(error));
  }
}

function* addBranchSaga() {
  yield takeEvery(actionsTypes.ADD_BRANCH_START, addBranch);
}

function* updateBranch(action) {
  try {
    if (action.payload) {
      const branchId = action.payload?._id;
      const response = yield BranchService.update(action.payload,branchId);//params
      if (response?.data.statusCode === 200) {
        yield put(actions.updateBranchSuccess(response.data));
      } else {
        yield put(actions.updateBranchFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.updateBranchFailure(error));
  }
}

function* updateBranchSaga() {
  yield takeEvery(actionsTypes.UPDATE_BRANCH_START, updateBranch);
}

export function* branchSaga() {
  yield all([fork(getBranchListSaga), fork(addBranchSaga), fork(updateBranchSaga)]);
}
