import { 
  dashboardIcon, 
  dashboardIconActive, 
  setting,
  settingActive,  
  restMngIcon, restMngIconActive, userMngIcon,userMngIconActive
} from '../assets/images/img';
import CategoryIcon from '@material-ui/icons/Category';
import TableChartIcon from '@material-ui/icons/TableChart';

let isLocal=false;
let frontendUrl="";
let restaurantService="";
let orderService ="";

if(isLocal){
  frontendUrl = `http://localhost:3001`; 
  restaurantService = `http://localhost:8080`;
  orderService= `http://localhost:8082`;
}
else{
  frontendUrl = `https://restaurant-admin.rockvillegroup.com`;
  restaurantService = `https://restaurant-api.rockvillegroup.com`;
  orderService = `https://restaurant-order.rockvillegroup.com`;
}

export const FRONTEND_DOMAIN_PREFIX =frontendUrl;
export const APP_DOMAIN_PREFIX_RESTAURANT = restaurantService;
export const APP_DOMAIN_PREFIX_ORDER = orderService;

export const TRACKING_ID = "G-QHQED5Z1NL";

 
export const API_URL_RESTAURANT = `${APP_DOMAIN_PREFIX_RESTAURANT}/api/v1`;
export const API_URL_ORDER = `${APP_DOMAIN_PREFIX_ORDER}/api/v1`;

export const FILE_BASE_URL = 'http://localhost:3004/';
 
export const LABELS = {
  GO_BACK: '← Back',
  LOGOUT: '↶ Logout',
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  REGISTER: 'Create User',
  EMAIL: 'Email Address',
  NAME: 'Username',
  FULL_NAME: 'Full Name',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  INVALID_MOBILE: 'Invalid mobile number'
};
export const PASSWORD = {
  passwordLength: 6,
  passwordLengthError: 'password is to short'
}
export const REGISTER = {
  SUCCESS_HEADER: "Success",
  SUCCESS_MESSAGE: "User Created Successfully!",
  FAILURE_HEADER: "Failure",
  FAILURE_MESSAGE: "Cannot Create User! User may already have been created with the given email!"
};
export const REGEXP_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2;3})+$/;
export const C_OTC_STORAGE = 'c_d_storage';



export const sidebarTabsList = [
  { text: 'dashboard', key: 'dashboard', icon: dashboardIcon, activeIcon: dashboardIconActive, role:['661fa91d0f11708b9bc19d43','661fa91d0f11708b9bc19d44','661fa91d0f11708b9bc19d45','661fa91d0f11708b9bc19d46','661fa91d0f11708b9bc19d47','661fa91d0f11708b9bc19d48'] },//65e95d88def02c6eb9f4836b','65e95d88def02c6eb9f4836c','65e95d88def02c6eb9f4836d','65e95d88def02c6eb9f4836e','65e95d88def02c6eb9f4836f'
  { text: 'user management', key: 'users', icon: userMngIcon, activeIcon: userMngIconActive, role:['661fa91d0f11708b9bc19d43','661fa91d0f11708b9bc19d44','661fa91d0f11708b9bc19d45','661fa91d0f11708b9bc19d46','661fa91d0f11708b9bc19d47','661fa91d0f11708b9bc19d48'], routeName:'User Management'}, 
  { text: 'Restuarant management', key: 'restaurant', icon: restMngIcon, activeIcon: restMngIconActive, role:['661fa91d0f11708b9bc19d43'], routeName:'Restaurant Management'},
  { text: 'Branch management', key: 'branch', icon: restMngIcon, activeIcon: restMngIconActive, role:['661fa91d0f11708b9bc19d44'], routeName:'Branch Management'},
  {text:  'Table Management',key:'table', icon: restMngIcon, activeIcon: restMngIcon,role:['661fa91d0f11708b9bc19d45','661fa91d0f11708b9bc19d46'],routeName:'Table Management'},
  { text: 'Category management', key: 'category', icon: restMngIcon, activeIcon: restMngIconActive, role:['661fa91d0f11708b9bc19d45'], routeName:'Category Management'},//'661fa91d0f11708b9bc19d46'
  { text: 'Menu management', key: 'menu', icon: restMngIcon, activeIcon: restMngIconActive, role:['661fa91d0f11708b9bc19d45'], routeName:'Menu Management'},
  { text: 'Order management', key: 'order', icon: restMngIcon, activeIcon: restMngIconActive, role:['661fa91d0f11708b9bc19d45','661fa91d0f11708b9bc19d46','661fa91d0f11708b9bc19d47','661fa91d0f11708b9bc19d48'], routeName:'Order Management'}, 
  { text: 'Global Configuration', key: 'globalConfig', icon: setting, activeIcon: settingActive, role:['661fa91d0f11708b9bc19d43'], routeName:'Global Configuration' },
];

export const jobStatus = ['pending', 'assigned', 'in route', 'delivered', 'completed'];

export const userRoles = [ 
  {text: 'Admin', type:1, value: "661fa91d0f11708b9bc19d43"},
  {text: 'Restaurant Admin', type:2, value: "661fa91d0f11708b9bc19d44"},
  {text: 'Branch Admin', type:3 ,value:"661fa91d0f11708b9bc19d45"},
  {text: 'Counter Manager', type:4, value: "661fa91d0f11708b9bc19d46"},//65e95d88def02c6eb9f4836c
  {text: 'Kitchen Manager', type:5, value: "661fa91d0f11708b9bc19d47"},  //65e95d88def02c6eb9f4836d
  {text: 'Waiter', type:6, value: "661fa91d0f11708b9bc19d48"},//65e95d88def02c6eb9f4836e
  // {text: 'Customer', value: "661fa91d0f11708b9bc19d49"},//65e95d88def02c6eb9f4836f
];


export const allowedMime=['PDF'];

export const rowsPerPageOption=[5,10,50,100];

export const userStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]
export const payNow = [
  { text: 'Pay-Now', value: '1' },
  { text: 'Pay-Later', value: '2' },
]
export const siteTheme = [
  { text: 'theme-one', value: '1' },
  { text: 'theme-two', value: '2' },
]
export const restaurantStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]
export const branchStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]
export const categoryStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]
export const menuStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]
export const postStatus = [
  { text: 'Published', value: '1' },
  { text: 'Unpublished', value: '0' },
]
export const yesNo = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 0 },
]
export const isAddon = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 2 },
]
export const isDrink = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 2 },
]

export const statusTypeOptions = [
  {text: 'All', value: ''},
  {text: 'Active', value: '1'},
  {text: 'In-Active', value: '0'},
]
export const deliveryStatusTypeOptions = [
  {text: 'Placed', value: 1},
  {text: 'Cooking', value: 2},
  {text: 'Completed', value: 3},
]
export const postTypeOptions = [
  {text: 'All', value: ''},
  {text: 'Published', value: '1'},
  {text: 'Unpublished', value: '0'},
]

export const country = [
  { value: 'AF', text: 'Afghanistan', currency: 'AFN' },
  { value: 'AL', text: 'Albania', currency: 'ALL' },
  { value: 'DZ', text: 'Algeria', currency: 'DZD' },
  { value: 'AD', text: 'Andorra', currency: 'EUR' },
  { value: 'AO', text: 'Angola', currency: 'AOA' },
  { value: 'AG', text: 'Antigua and Barbuda', currency: 'XCD' },
  { value: 'AR', text: 'Argentina', currency: 'ARS' },
  { value: 'AM', text: 'Armenia', currency: 'AMD' },
  { value: 'AU', text: 'Australia', currency: 'AUD' },
  { value: 'AT', text: 'Austria', currency: 'EUR' },
  { value: 'AZ', text: 'Azerbaijan', currency: 'AZN' },
  { value: 'BS', text: 'Bahamas', currency: 'BSD' },
  { value: 'BH', text: 'Bahrain', currency: 'BHD' },
  { value: 'BD', text: 'Bangladesh', currency: 'BDT' },
  { value: 'BB', text: 'Barbados', currency: 'BBD' },
  { value: 'BY', text: 'Belarus', currency: 'BYN' },
  { value: 'BE', text: 'Belgium', currency: 'EUR' },
  { value: 'BZ', text: 'Belize', currency: 'BZD' },
  { value: 'BJ', text: 'Benin', currency: 'XOF' },
  { value: 'BT', text: 'Bhutan', currency: 'BTN' },
  { value: 'BO', text: 'Bolivia', currency: 'BOB' },
  { value: 'BA', text: 'Bosnia and Herzegovina', currency: 'BAM' },
  { value: 'BW', text: 'Botswana', currency: 'BWP' },
  { value: 'BR', text: 'Brazil', currency: 'BRL' },
  { value: 'BN', text: 'Brunei', currency: 'BND' },
  { value: 'BG', text: 'Bulgaria', currency: 'BGN' },
  { value: 'BF', text: 'Burkina Faso', currency: 'XOF' },
  { value: 'BI', text: 'Burundi', currency: 'BIF' },
  { value: 'KH', text: 'Cambodia', currency: 'KHR' },
  { value: 'CM', text: 'Cameroon', currency: 'XAF' },
  { value: 'CA', text: 'Canada', currency: 'CAD' },
  { value: 'CV', text: 'Cape Verde', currency: 'CVE' },
  { value: 'CF', text: 'Central African Republic', currency: 'XAF' },
  { value: 'TD', text: 'Chad', currency: 'XAF' },
  { value: 'CL', text: 'Chile', currency: 'CLP' },
  { value: 'CN', text: 'China', currency: 'CNY' },
  { value: 'CO', text: 'Colombia', currency: 'COP' },
  { value: 'KM', text: 'Comoros', currency: 'KMF' },
  { value: 'CD', text: 'Congo (Democratic Republic of the)', currency: 'CDF' },
  { value: 'CG', text: 'Congo (Republic of the)', currency: 'XAF' },
  { value: 'CR', text: 'Costa Rica', currency: 'CRC' },
  { value: 'HR', text: 'Croatia', currency: 'HRK' },
  { value: 'CU', text: 'Cuba', currency: 'CUP' },
  { value: 'CY', text: 'Cyprus', currency: 'EUR' },
  { value: 'CZ', text: 'Czech Republic', currency: 'CZK' },
  { value: 'DK', text: 'Denmark', currency: 'DKK' },
  { value: 'DJ', text: 'Djibouti', currency: 'DJF' },
  { value: 'DM', text: 'Dominica', currency: 'XCD' },
  { value: 'DO', text: 'Dominican Republic', currency: 'DOP' },
  { value: 'EC', text: 'Ecuador', currency: 'USD' },
  { value: 'EG', text: 'Egypt', currency: 'EGP' },
  { value: 'SV', text: 'El Salvador', currency: 'USD' },
  { value: 'GQ', text: 'Equatorial Guinea', currency: 'XAF' },
  { value: 'ER', text: 'Eritrea', currency: 'ERN' },
  { value: 'EE', text: 'Estonia', currency: 'EUR' },
  { value: 'ET', text: 'Ethiopia', currency: 'ETB' },
  { value: 'FJ', text: 'Fiji', currency: 'FJD' },
  { value: 'FI', text: 'Finland', currency: 'EUR' },
  { value: 'FR', text: 'France', currency: 'EUR' },
  { value: 'GA', text: 'Gabon', currency: 'XAF' },
  { value: 'GM', text: 'Gambia', currency: 'GMD' },
  { value: 'GE', text: 'Georgia', currency: 'GEL' },
  { value: 'DE', text: 'Germany', currency: 'EUR' },
  { value: 'GH', text: 'Ghana', currency: 'GHS' },
  { value: 'GR', text: 'Greece', currency: 'EUR' },
  { value: 'GD', text: 'Grenada', currency: 'XCD' },
  { value: 'GT', text: 'Guatemala', currency: 'GTQ' },
  { value: 'GN', text: 'Guinea', currency: 'GNF' },
  { value: 'GW', text: 'Guinea-Bissau', currency: 'XOF' },
  { value: 'GY', text: 'Guyana', currency: 'GYD' },
  { value: 'HT', text: 'Haiti', currency: 'HTG' },
  { value: 'HN', text: 'Honduras', currency: 'HNL' },
  { value: 'HU', text: 'Hungary', currency: 'HUF' },
  { value: 'IS', text: 'Iceland', currency: 'ISK' },
  { value: 'IN', text: 'India', currency: 'INR' },
  { value: 'ID', text: 'Indonesia', currency: 'IDR' },
  { value: 'IR', text: 'Iran', currency: 'IRR' },
  { value: 'IQ', text: 'Iraq', currency: 'IQD' },
  { value: 'IE', text: 'Ireland', currency: 'EUR' },
  { value: 'IL', text: 'Israel', currency: 'ILS' },
  { value: 'IT', text: 'Italy', currency: 'EUR' },
  { value: 'JM', text: 'Jamaica', currency: 'JMD' },
  { value: 'JP', text: 'Japan', currency: 'JPY' },
  { value: 'JO', text: 'Jordan', currency: 'JOD' },
  { value: 'KZ', text: 'Kazakhstan', currency: 'KZT' },
  { value: 'KE', text: 'Kenya', currency: 'KES' },
  { value: 'KI', text: 'Kiribati', currency: 'AUD' },
  { value: 'KW', text: 'Kuwait', currency: 'KWD' },
  { value: 'KG', text: 'Kyrgyzstan', currency: 'KGS' },
  { value: 'LA', text: 'Laos', currency: 'LAK' },
  { value: 'LV', text: 'Latvia', currency: 'EUR' },
  { value: 'LB', text: 'Lebanon', currency: 'LBP' },
  { value: 'LS', text: 'Lesotho', currency: 'LSL' },
  { value: 'LR', text: 'Liberia', currency: 'LRD' },
  { value: 'LY', text: 'Libya', currency: 'LYD' },
  { value: 'LI', text: 'Liechtenstein', currency: 'CHF' },
  { value: 'LT', text: 'Lithuania', currency: 'EUR' },
  { value: 'LU', text: 'Luxembourg', currency: 'EUR' },
  { value: 'MK', text: 'Macedonia', currency: 'MKD' },
  { value: 'MG', text: 'Madagascar', currency: 'MGA' },
  { value: 'MW', text: 'Malawi', currency: 'MWK' },
  { value: 'MY', text: 'Malaysia', currency: 'MYR' },
  { value: 'MV', text: 'Maldives', currency: 'MVR' },
  { value: 'ML', text: 'Mali', currency: 'XOF' },
  { value: 'MT', text: 'Malta', currency: 'EUR' },
  { value: 'MH', text: 'Marshall Islands', currency: 'USD' },
  { value: 'MR', text: 'Mauritania', currency: 'MRU' },
  { value: 'MU', text: 'Mauritius', currency: 'MUR' },
  { value: 'MX', text: 'Mexico', currency: 'MXN' },
  { value: 'FM', text: 'Micronesia', currency: 'USD' },
  { value: 'MD', text: 'Moldova', currency: 'MDL' },
  { value: 'MC', text: 'Monaco', currency: 'EUR' },
  { value: 'MN', text: 'Mongolia', currency: 'MNT' },
  { value: 'ME', text: 'Montenegro', currency: 'EUR' },
  { value: 'MA', text: 'Morocco', currency: 'MAD' },
  { value: 'MZ', text: 'Mozambique', currency: 'MZN' },
  { value: 'MM', text: 'Myanmar (Burma)', currency: 'MMK' },
  { value: 'NA', text: 'Namibia', currency: 'NAD' },
  { value: 'NR', text: 'Nauru', currency: 'AUD' },
  { value: 'NP', text: 'Nepal', currency: 'NPR' },
  { value: 'NL', text: 'Netherlands', currency: 'EUR' },
  { value: 'NZ', text: 'New Zealand', currency: 'NZD' },
  { value: 'NI', text: 'Nicaragua', currency: 'NIO' },
  { value: 'NE', text: 'Niger', currency: 'XOF' },
  { value: 'NG', text: 'Nigeria', currency: 'NGN' },
  { value: 'NO', text: 'Norway', currency: 'NOK' },
  { value: 'OM', text: 'Oman', currency: 'OMR' },
  { value: 'PK', text: 'Pakistan', currency: 'PKR' },
  { value: 'PW', text: 'Palau', currency: 'USD' },
  { value: 'PA', text: 'Panama', currency: 'PAB' },
  { value: 'PG', text: 'Papua New Guinea', currency: 'PGK' },
  { value: 'PY', text: 'Paraguay', currency: 'PYG' },
  { value: 'PE', text: 'Peru', currency: 'PEN' },
  { value: 'PH', text: 'Philippines', currency: 'PHP' },
  { value: 'PL', text: 'Poland', currency: 'PLN' },
  { value: 'PT', text: 'Portugal', currency: 'EUR' },
  { value: 'QA', text: 'Qatar', currency: 'QAR' },
  { value: 'RO', text: 'Romania', currency: 'RON' },
  { value: 'RU', text: 'Russia', currency: 'RUB' },
  { value: 'RW', text: 'Rwanda', currency: 'RWF' },
  { value: 'KN', text: 'Saint Kitts and Nevis', currency: 'XCD' },
  { value: 'LC', text: 'Saint Lucia', currency: 'XCD' },
  { value: 'VC', text: 'Saint Vincent and the Grenadines', currency: 'XCD' },
  { value: 'WS', text: 'Samoa', currency: 'WST' },
  { value: 'SM', text: 'San Marino', currency: 'EUR' },
  { value: 'ST', text: 'São Tomé and Príncipe', currency: 'STN' },
  { value: 'SA', text: 'Saudi Arabia', currency: 'SAR' },
  { value: 'SN', text: 'Senegal', currency: 'XOF' },
  { value: 'RS', text: 'Serbia', currency: 'RSD' },
  { value: 'SC', text: 'Seychelles', currency: 'SCR' },
  { value: 'SL', text: 'Sierra Leone', currency: 'SLL' },
  { value: 'SG', text: 'Singapore', currency: 'SGD' },
  { value: 'SK', text: 'Slovakia', currency: 'EUR' },
  { value: 'SI', text: 'Slovenia', currency: 'EUR' },
  { value: 'SB', text: 'Solomon Islands', currency: 'SBD' },
  { value: 'SO', text: 'Somalia', currency: 'SOS' },
  { value: 'ZA', text: 'South Africa', currency: 'ZAR' },
  { value: 'KR', text: 'South Korea', currency: 'KRW' },
  { value: 'SS', text: 'South Sudan', currency: 'SSP' },
  { value: 'ES', text: 'Spain', currency: 'EUR' },
  { value: 'LK', text: 'Sri Lanka', currency: 'LKR' },
  { value: 'SD', text: 'Sudan', currency: 'SDG' },
  { value: 'SR', text: 'Suriname', currency: 'SRD' },
  { value: 'SZ', text: 'Swaziland', currency: 'SZL' },
  { value: 'SE', text: 'Sweden', currency: 'SEK' },
  { value: 'CH', text: 'Switzerland', currency: 'CHF' },
  { value: 'SY', text: 'Syria', currency: 'SYP' },
  { value: 'TW', text: 'Taiwan', currency: 'TWD' },
  { value: 'TJ', text: 'Tajikistan', currency: 'TJS' },
  { value: 'TZ', text: 'Tanzania', currency: 'TZS' },
  { value: 'TH', text: 'Thailand', currency: 'THB' },
  { value: 'TL', text: 'Timor-Leste', currency: 'USD' },
  { value: 'TG', text: 'Togo', currency: 'XOF' },
  { value: 'TO', text: 'Tonga', currency: 'TOP' },
  { value: 'TT', text: 'Trinidad and Tobago', currency: 'TTD' },
  { value: 'TN', text: 'Tunisia', currency: 'TND' },
  { value: 'TR', text: 'Turkey', currency: 'TRY' },
  { value: 'TM', text: 'Turkmenistan', currency: 'TMT' },
  { value: 'TV', text: 'Tuvalu', currency: 'AUD' },
  { value: 'UG', text: 'Uganda', currency: 'UGX' },
  { value: 'UA', text: 'Ukraine', currency: 'UAH' },
  { value: 'AE', text: 'United Arab Emirates', currency: 'AED' },
  { value: 'GB', text: 'United Kingdom', currency: 'GBP' },
  { value: 'US', text: 'United States', currency: 'USD' },
  { value: 'UY', text: 'Uruguay', currency: 'UYU' },
  { value: 'UZ', text: 'Uzbekistan', currency: 'UZS' },
  { value: 'VU', text: 'Vanuatu', currency: 'VUV' },
  { value: 'VA', text: 'Vatican City', currency: 'EUR' },
  { value: 'VE', text: 'Venezuela', currency: 'VES' },
  { value: 'VN', text: 'Vietnam', currency: 'VND' },
  { value: 'YE', text: 'Yemen', currency: 'YER' },
  { value: 'ZM', text: 'Zambia', currency: 'ZMW' },
  { value: 'ZW', text: 'Zimbabwe', currency: 'ZWL' }
];
 
export const YesNoOption = [
  { text: 'Yes', value: '1' },
  { text: 'No', value: '0' },
]

export const ImageOption = [
  { text: 'Image', value: '1' },
  { text: 'Video', value: '2' },
]

export const FileOption = [
  { text: 'Embeded', value: '0' },
  { text: 'Download', value: '1' },
]
 
export function getFormattedDate(date) {
  const date1 = new Date(date);                 // {object Date}
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let year = date1.getFullYear();
  let month = monthNames[date1.getMonth()];
  let day = date1.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
}
 
export const statusFailureCase = [3, 6, 8, 9, 11]
export const statusFailureCase1 = [5, 6, 7, 8, 9, 10, 11]

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const mobileNumberRegex = /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,}$/im

 