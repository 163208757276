import { da } from "date-fns/locale";
import * as actionTypes from "../actionTypes";

export const getOrderList = (data) => {
    return {
        type: actionTypes.GET_ORDER_LIST_START,
        payload: data,
    };
};
export const getOrderListSuccess = (data) => {
    return {
        type: actionTypes.GET_ORDER_LIST_SUCCESS,
        payload: data,
    };
};

export const getOrderListFailure = (data) => {
    return {
        type: actionTypes.GET_ORDER_LIST_FAILURE,
        payload: data,
    };
};

export const addOrder = (data) => {
    return {
        type: actionTypes.ADD_ORDER_START,
        payload: data,
    };
};

export const addOrderSuccess = (data) => {
    return {
        type: actionTypes.ADD_ORDER_SUCCESS,
        payload: data,
    };
};

export const addOrderFailure = (data) => {
    return {
        type: actionTypes.ADD_ORDER_FAILURE,
        payload: data,
    };
};

export const updateOrder = (data) => {
    return {
        type: actionTypes.UPDATE_ORDER_START,
        payload: data,
    };
};
export const updateOrderSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_ORDER_SUCCESS,
        payload: data,
    };
};

export const updateOrderFailure = (data) => {
    return {
        type: actionTypes.UPDATE_ORDER_FAILURE,
        payload: data,
    };
};

export const messageHandler = () => {
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};