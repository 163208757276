import * as actionTypes from "../actionTypes";
import {setUserDataInLocalStorage} from "../../services/utils";

export const loginStart = (data) => {
  console.log("action data ",data);
  return {
    type: actionTypes.LOGIN_START,
    payload: data,
  };
};
 

export const loginSuccess = (data) => {
  console.log('loginSuccess auth.js ',data.user);
  localStorage.setItem('token', data?.access_token);
  setUserDataInLocalStorage(data?.user);

  let d = {user: null, message: 'Successfully loggedIn '}
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: d,
  };
};

export const isLoggedIn = () => {
  // console.log('isLoggedIn auth.js ');
  let d = {user: null, message: 'Successfully loggedIn '}
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: d,
  };
};

export const loginFailure = (error) => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: error,
  };
};

export const signupStart = (data) => {
  return {
    type: actionTypes.SIGNUP_START,
    payload: data,
  };
};

export const signupSuccess = (data, signup) => {
  let obj = {}
  if (signup) {
    setUserDataInLocalStorage(data.data.user);
    localStorage.setItem('token', data.data.token);
    obj = {user: data.data.user, success: data.message}
  } else {
    obj = {user: null, success: data.message}
  }
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    payload: obj,
  };
};
export const signupFailure = (error) => {
  return {
    type: actionTypes.SIGNUP_FAILURE,
    payload: error,
  };
};
export const logout = () => {
  // console.log('In logout auth.js ==== ');
  return {
    type: actionTypes.LOGOUT,
    payload: null,
  };
};

export const logoutSuccess = () => {
  console.log('logoutSuccess auth.js')
  console.log("localStorage",localStorage);
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.clear();
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    payload: null,
  };
};

export const loginErrorHandlerSuccess = () => {
  return {
    type: actionTypes.LOGIN_ERROR_HANDLER_SUCCESS,
    payload: null,
  };
};


export const listQuerySuccess = (data) => {
  //console.log('managePostSuccess ',data);
  return {
      type: actionTypes.LIST_QUERY_SUCCESS,
      payload: data,
  };
};

export const listQueryFailure = (data) => {
  return {
      type: actionTypes.LIST_QUERY_FAILURE,
      payload: data,
  };
};

export const listQuery = (data) => {
  return {
      type: actionTypes.LIST_QUERY_START,
      payload: data,
  };
};

export const manageConfig = (data) => {
  return {
      type: actionTypes.MANAGE_CONFIG_START,
      payload: data,
  };
};
export const manageConfigSuccess = (data) => {
  return {
      type: actionTypes.MANAGE_CONFIG_SUCCESS,
      payload: data,
  };
};
export const manageConfigFailure = (data) => {
  return {
      type: actionTypes.MANAGE_CONFIG_FAILURE,
      payload: data,
  };
};

export const getConfig = (data) => {
  return {
      type: actionTypes.GET_CONFIG_START,
      payload: data,
  };
};
export const getConfigSuccess = (data) => {
  return {
      type: actionTypes.GET_CONFIG_SUCCESS,
      payload: data,
  };
};
export const getConfigFailure = (data) => {
  return {
      type: actionTypes.GET_CONFIG_FAILURE,
      payload: data,
  };
};
