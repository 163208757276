export const dashboardPage = [
    {intro: 'Serai Restaurant content management system.', label: "Intro"}, 
    {sideBar: 'The side bar menu contains all the tabs form where various sections of the website.', label: "Sidebar"}
]

export const userHomePage = [ 

]

export const userManagement = [ 
     
] 

export const addUser = [ 
    {intro: 'Fill in the form data.', label: "Intro"},
    {dash: 'Click on submit button. User will be added successfully.', label: "Submit"},
] 

export const userPermission = [ 
  
] 

export const userHistory = [ 
    {intro: 'User previous login hisotry.', label: "Intro"},
] 

export const projectManagement = [ 
    
] 

export const contentManagement = [ 
 
] 

export const editContent = [ 
 
] 

export const newsViews  = [ 
 
]

export const galleryManagement = [ 
 
]
 
export const generalInfo = [ 
 
] 
 
export const globalConfig = [ 
    {intro: 'This section manages the contact details in footer of website like address, social media handle links and other details.', label: "Intro"},
] 

export const seoManagement = [ 
    {intro: 'User can add search engine data.', label: "Intro"},
] 

export const fileHelp = [ 
    {intro: 'User can add doc & files.', label: "Intro"},
] 

export const creatUserPage = [
    {createUser: 'Create user by completing the profile.', label: "Create User"},
    {Role: 'Assign Role to the user.', label: "Role"},
]
    