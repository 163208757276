import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  usersList: null,

  usersListError: null,
  userLoading: false,

  waitersList:null,
  waitersListError:null,

  user: null,
  userDataError: null,

  userPermissionList:[],
  manageUserPermission:null,

  loginHistoryList:[],
  loginHistoryListError:null,

  moduleList:[],
  moduleListError:null,

  success: null,
  error: null,
  servicesList: null,

};
export const usersReducer = (state = initialState, action) => {
  //console.log(action.type,' usersReducer ',action.payload);
  switch (action.type) {
    case actionTypes.GET_USERS_LIST_START:
      return { ...state, loading: true, usersListError: null, usersList: null };
    case actionTypes.GET_USERS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        usersListError: null,
        usersList: action.payload,
      };
    };
    case actionTypes.GET_USERS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        usersListError: action.payload,
        usersList: null,
      };

    case actionTypes.GET_WAITERS_LIST_START:
      console.log("get waiter list start Reducer");
      return { ...state, loading: true, waitersListError: null, waitersList: null };
    case actionTypes.GET_WAITERS_LIST_SUCCESS: {
      console.log("get waiter list success Reducer",action.payload);
      return {
        ...state,
        loading: false,
        waitersListError: null,
        waitersList: action.payload,
      };
    };
    case actionTypes.GET_WAITERS_LIST_FAILURE:{
      console.log("get waiter list failuer Reducer");
      return {
        ...state,
        loading: false,
        waitersListError: action.payload,
        waitersList: null,
      };
    };
  



    case actionTypes.GET_USER_DATA_START:
      return { ...state, userLoading: true, userDataError: null, user: null };
    case actionTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userDataError: null,
        user: action.payload,
      };
    case actionTypes.GET_USER_DATA_FAILURE:
      return {
        ...state,
        userLoading: false,
        userDataError: action.payload,
        user: null,
      };

    case actionTypes.UPDATE_USER_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null
      };
    case actionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      };
      
    case actionTypes.ADD_NEW_USER_START:
        return { ...state, loading: true, success: null, error: null };
    case actionTypes.ADD_NEW_USER_SUCCESS:
      return {
          ...state,
          loading: false,
          success: action.payload,
          error: null
      };
      case actionTypes.ADD_NEW_USER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,   
          success: null,
        }; 

    case actionTypes.DELETE_USER_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null
      };
    case actionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case actionTypes.LIST_USER_PERMISSION_START:
      return { ...state, loading: true, success: null, userPermissionList:[], error: null };
    case actionTypes.LIST_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload?.message,
        userPermissionList: action.payload,
        error: null
      };
    case actionTypes.LIST_USER_PERMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case actionTypes.MANAGE_USER_PERMISSION_START:
      return { ...state, loading: true, success: null, manageUserPermission:null, error: null };
    case actionTypes.MANAGE_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload?.message,
        manageUserPermission: action.payload?.data,
        error: null
      };
    case actionTypes.MANAGE_USER_PERMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case actionTypes.LIST_MODULE_START:
      return { ...state, loading: true, success: null, moduleList:[], moduleListError:null, error: null };
    case actionTypes.LIST_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload?.message,
        moduleList: action.payload,
        error: null
      };
    case actionTypes.LIST_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload || null,
        moduleListError:action.payload,
        success: null,
      };

    case actionTypes.LIST_LOGIN_HISTORY_START:
      return { ...state, loading: true, success: null, loginHistoryList:[], loginHistoryListError:null, error: null };
    case actionTypes.LIST_LOGIN_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        loginHistoryList: action.payload,
        error: null
      };
    case actionTypes.LIST_LOGIN_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload || null,
        loginHistoryListError: action.payload || null,
        success: null,
      };

    ////////userServicesList//////////////////////
    case actionTypes.SET_USER_SERVICES_LIST:
      // console.log("state",action.payload);
      return {
        ...state,
        servicesList: action.payload,
      };

    case actionTypes.MESSAGE_HANDLER:
      return {
        ...state,
        loading: false,
        usersListError: action.payload,
        userDataError: action.payload,
        error: action.payload,
        success: action.payload,
      };
    default:
      return { ...state };
  }
};
