import React from 'react';
import Typography from "@material-ui/core/Typography";
import SearchBar from "../../search"; 
import {AddButton} from '../../index'

function Index(props) {
  const {
    tableLabel, searchValue, setSearchValue, newIconHandler, showSearch = true, showAdd = true,
     searchPlaceholder = 'search...',  
    className,styles,loading
  } = props;
  return (
    <div className={` table-title-container ${className}`}style={styles}>
      <Typography variant='h4' className='table-title'>
        {tableLabel}
      </Typography>

      {(searchValue != null || showAdd) && 
        <div className='search-bar-container'>
          {showSearch && <SearchBar value={searchValue} onChange={setSearchValue} placeholder={searchPlaceholder}/>}
          {showAdd &&
            <AddButton addHandler={newIconHandler} loading={loading}/>} 
        </div>
      }
    </div>
  );
}

export default Index;
