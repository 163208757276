import * as actionTypes from "../actionTypes";
// below function is used to get list of tables 
export const getTableList = (data) => {//getGalleryList()
    return {
        type: actionTypes.GET_TABLE_LIST_START,
        payload: data,
    };
};
export const getTableListSuccess = (data) => {//getGalleryListSuccess()
    return {
        type: actionTypes.GET_TABLE_LIST_SUCCESS,
        payload: data,
    };
};
export const getTableListFailure = (data) => {//getGalleryListFailure
    return {
        type: actionTypes.GET_TABLE_LIST_FAILURE,
        payload: data,
    };
};

// below manage functions are used to add or update table 
export const manageTable = (data) => { // manageGallery
    return {
        type: actionTypes.MANAGE_TABLE_START,
        payload: data,
    };
};
export const manageTableSuccess = (data) => {//managePostSuccess
    //console.log('managePostSuccess ',data);
    return {
        type: actionTypes.MANAGE_TABLE_SUCCESS,
        payload: data,
    };
};
export const manageTableFailure = (data) => {//managePostFailure
    return {
        type: actionTypes.MANAGE_TABLE_FAILURE,
        payload: data,
    };
};



/*
export const getGalleryData = (data) => {
    return {
        type: actionTypes.GET_GALLERY_DATA_START,
        payload: data,
    };
};
export const getGalleryDataSuccess = (data) => {
    return {
        type: actionTypes.GET_GALLERY_DATA_SUCCESS,
        payload: data,

    };
};
export const getGalleryDataFailure = (data) => {
    return {
        type: actionTypes.GET_GALLERY_DATA_FAILURE,
        payload: data,
    };
};
*/


// export const manageTableSuccess = (data) => { //manageGallerySuccess
//     return {
//         type: actionTypes.MANAGE_TABLE_SUCCESS,
//         payload: data,
//     };
// };

// export const manageTableFailure = (data) => { //manageGalleryFailure
//     return {
//         type: actionTypes.MANAGE_TABLE_FAILURE,
//         payload: data,
//     };
// };

/* these update functions are not used because update case is aslo handled by manage table functions
export const updateGallery = (data) => {
    return {
        type: actionTypes.UPDATE_TABLE_START,
        payload: data,
    };
};

export const updateGallerySuccess = (data) => {
    return {
        type: actionTypes.UPDATE_TABLE_SUCCESS,
        payload: data,
    };
};
export const updateGalleryFailure = (data) => {
    return {
        type: actionTypes.UPDATE_TABLE_FAILURE,
        payload: data,
    };
};
*/

/*
export const deleteGallery = (data) => {
    return {
        type: actionTypes.DELETE_GALLERY_START,
        payload: data,
    };
};
export const deleteGallerySuccess = (data) => {
    return {
        type: actionTypes.DELETE_GALLERY_SUCCESS,
        payload: data,
    };
};
export const deleteGalleryFailure = (data) => {
    return {
        type: actionTypes.DELETE_GALLERY_FAILURE,
        payload: data,
    };
};
*/

export const messageHandler = () => {
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};
/*
export const manageVideo= (data) => {
    return {
        type: actionTypes.MANAGE_VIDEO_START,
        payload: data,
    };
};

export const manageVideoSuccess = (data) => {
    return {
        type: actionTypes.MANAGE_VIDEO_SUCCESS,
        payload: data,
    };
};

export const manageVideoFailure = (data) => {
    return {
        type: actionTypes.MANAGE_VIDEO_FAILURE,
        payload: data,
    };
};
*/
/*
export const manageFile = (data) => {
    return {
        type: actionTypes.MANAGE_FILE_START,
        payload: data,
    };
};

export const manageFileSuccess = (data) => {
    return {
        type: actionTypes.MANAGE_FILE_SUCCESS,
        payload: data,
    };
};

export const manageFileFailure = (data) => {
    return {
        type: actionTypes.MANAGE_FILE_FAILURE,
        payload: data,
    };
};
*/

/* 
these methods are not used
export const getFileList = (data) => {
     
    return {
        type: actionTypes.GET_FILE_LIST_START,
        payload: data,
    };
};
export const getFileListSuccess = (data) => {
    console.log("file data coming against fetch file Record request",data); 
    return {
        type: actionTypes.GET_FILE_LIST_SUCCESS,
        payload: data,
    };
};
export const getFileListFailure = (data) => {
    return {
        type: actionTypes.GET_FILE_LIST_FAILURE,
        payload: data,
    };
};*/

/*
export const deleteFile = (data) => {
    return {
        type: actionTypes.DELETE_FILE_START,
        payload: data,
    };
};
export const deleteFileSuccess = (data) => {
    return {
        type: actionTypes.DELETE_FILE_SUCCESS,
        payload: data,
    };
};
export const deleteFileFailure = (data) => {
    return {
        type: actionTypes.DELETE_FILE_FAILURE,
        payload: data,
    };
};
*/

