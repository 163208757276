import {all} from "redux-saga/effects";
import {authSaga} from "./authSagas";
import {dashboardSaga} from "./dashboardSagas";
import {userSaga} from "./userSagas";
import {categorySaga} from "./categorySagas";
import {restaurantSaga} from "./restaurantSagas";
import {branchSaga} from "./branchSagas";
import {menuSaga} from "./menuSagas";
import {orderSaga} from "./orderSagas";
// import {gallerySaga} from "./gallerySagas";
// import {fileUploadSaga} from "./fileUploadSaga";
import {tableSaga} from "./tableSagas";
export function* rootSaga(getState) {
  yield all([
    authSaga(),
    dashboardSaga(),
    userSaga(),
    categorySaga(),
    restaurantSaga(),
    branchSaga(),
    menuSaga(),
    orderSaga(),
    // gallerySaga(),
    // fileUploadSaga(),
    tableSaga(),
  ]);
}
