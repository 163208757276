import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import TableService from '../../services/table.service'


function* getTableList(action) { //getGalleryList
  try {
    console.log('getTableList action userSagas============ ',action);
    console.log("action.payload in table list ",action.payload.post_id)
    let restaurant_branch_id = action.payload.post_id;
    // if (action.payload) {
      // const category_id = action.payload?.category_id;
      // const post_id = action.payload?.post_id;
      // const type = action.payload?.type;
      // let params = {category_id, post_id,type};

      const response = yield TableService.list(restaurant_branch_id);//params
      console.log('Get Table List response TableSagas============ ',response);
      if (response?.status === 200) {
        console.log('response.data GallerySaga ',response.data);
        yield put(actions.getTableListSuccess(response.data)); //getGalleryListSuccess
      } else {
        yield put(actions.getTableListFailure("network error"));//getGalleryListFailure
      }
    // }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getTableListFailure(error));  //getGalleryListFailure
   }
}

function* manageTable(action){ //manageAsset
  try {
    console.log('managePost GallerySagas  payload ',action);
    let response;
    let payload = action.payload;
    console.log("payload",payload);
    if(payload._id){
      console.log("data for updating record  has id in saga",payload._id);
      console.log("data for update in the payload",payload);
      const table_id = payload?._id;
      const table_code = payload?.code;
      const table_person = payload?.person;
      const table_restaurant_branch_id = payload?.restaurant_branch_id;
      const table_waiter_id = payload?.waiter_id;
      const table_status = payload?.status;
      let params ={};
      params.code = table_code;
      params.person = table_person;
      params.restaurant_branch_id = table_restaurant_branch_id;
      params.waiter_id = table_waiter_id;
      params.status = table_status;
      response = yield TableService.updateTable(
        table_id, params,
      );
      // if (response?.data.statusCode === 200) {
      //   //console.log('response.data GallerySagas ',response.data);
      //   yield put(actions.manageGallerySuccess(response.data));
      // } else {
      //   yield put(actions.manageGalleryFailure("network error"));
      // }

    }
    else{
      response = yield TableService.manageTable(payload)
      // if (response?.data.statusCode === 201) {
      //   //console.log('response.data GallerySagas ',response.data);
      //   yield put(actions.manageGallerySuccess(response.data));
      // } else {
      //   yield put(actions.manageGalleryFailure("network error"));
      // }
    }
    // const {  restaurant_id,logo } = action.payload;
    // const formData = new FormData();
    // formData.append('restaurant_id', restaurant_id);
    // formData.append('logo', logo);
    // let create = payload.create;
    // delete payload.create;
    // restaurant_id = payload?.restaurant_id;
    // console.log("restaurant_id",restaurant_id);
    // console.log('managePost GallerySagas ',create,' payload ',payload);
    // if (create) {
    //   // create user flow
    //   delete payload._id
    // }
  //  response = yield AssetService.manageTable(payload);// restaurant_id,// formData,
    // console.log('Submit resposne response gallerySagas ============ ',response);
    if (response?.data.statusCode === 201 || 200) {
      //console.log('response.data GallerySagas ',response.data);
      yield put(actions.manageTableSuccess(response.data));//manageGallerySuccess
    } else {
      yield put(actions.manageTableFailure("network error"));//manageGalleryFailure
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.manageTableFailure(error));//signupFailure
  }
}

function* getTableListSaga() { //getGalleryListSaga
    yield takeEvery(actionsTypes.GET_TABLE_LIST_START, getTableList);//GET_GALLERY_LIST_START
}
  
function* manageTableSaga(){//manageGallerySaga
    yield takeEvery(actionsTypes.MANAGE_TABLE_START, manageTable);//MANAGE_GALLERY_START
}


// function* manageVideoLink(action){
//   try {
//   console.log('managePost GallerySagas  payload ',action);

//     let response;
//     let payload = action.payload;
//     let create = payload.create;
//     delete payload.create;
//     // console.log('managePost GallerySagas ',create,' payload ',payload);
//     if (create) {
//       // create user flow
//       delete payload._id
//     }
//     response = yield AssetService.manageVideoLink(payload);
//     //console.log('managePost response GallerySagas============ ',response);
//     if (response?.data.statusCode === 200) {
//       //console.log('response.data GallerySagas ',response.data);
//       yield put(actions.manageVideoSuccess(response.data));
//     } else {
//       yield put(actions.manageVideoFailure("network error"));
//     }
//   } catch (err) {
//     // console.log('er ', err)
//     let error = err?.response?.data?.message;
//     if (!error) {
//       error = "network error"
//     }
//     yield put(actions.signupFailure(error));
//   }
// }

// function* deleteAsset(action){
//   try {
//   console.log('managePost GallerySagas  payload ',action);
//     let response;
//     let payload = action.payload;
    
//     response = yield AssetService.deleteImage(payload);
//     //console.log('managePost response GallerySagas============ ',response);
//     if (response?.data.statusCode === 200) {
//       //console.log('response.data GallerySagas ',response.data);
//       yield put(actions.deleteGallerySuccess(response.data));
//     } else {
//       yield put(actions.deleteGalleryFailure("network error"));
//     }
//   } catch (err) {
//     let error = err?.response?.data?.message;
//     if (!error) {
//       error = "network error"
//     }
//     // yield put(actions.signupFailure(error));
//   }
// }



// function* manageVideoSaga(){
//   yield takeEvery(actionsTypes.MANAGE_VIDEO_START, manageVideoLink);
// }
// function* deleteGallerySaga(){
//   yield takeEvery(actionsTypes.DELETE_GALLERY_START, deleteAsset);
// }

export function* tableSaga() {
  yield all([fork(getTableListSaga),//getGalleryListSaga
    fork(manageTableSaga),//manageGallerySaga
    // fork(manageVideoSaga),
    //fork(deleteGallerySaga)
  ]);
}
