import {C_OTC_STORAGE,   mobileNumberRegex, PASSWORD} from "../environment";
 
export const getUserDataFromLocalStorage = () => {
  const userObj =
    (localStorage.getItem(C_OTC_STORAGE) &&
      JSON.parse(localStorage.getItem(C_OTC_STORAGE))) ||
    null;
  return userObj;
};
export const setUserDataInLocalStorage = (user) => {
  // console.log("utlis user",user);
  localStorage.setItem(C_OTC_STORAGE, JSON.stringify(user));
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
 

export const validateMobile = (value) => { // check minimum length 7 max length is out bound
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (value.match(mobileNumberRegex));
} 
export const validatePassword = (password) => {
  if (
    (password !== undefined) && (password.trim() === "" || password.length < PASSWORD.passwordLength)
    // || !values.password.match('[a-z]')
    // || !values.password.match('[A-Z]')
    // || !values.password.match('[0-9]')
    // || !values.password.match(/[\*\.\!\@\#\$\%\^\&\(\)\{\}\[\]\:\;\<\>\,\.\?\/\~\_\+\-\=\|\\]/)
  ) {
    return (PASSWORD.passwordLengthError)
  }
  return null;

}
export const onChangeInput = (value, key, prevState, setState) => {
  setState((prevState) => ({
    ...prevState,
    values: {
      ...prevState.values,
      [key]: value,
    },
    errors: {
      ...prevState.errors,
      [key]: "",
    }
  }));
};

export const validateInputs = (values) => {
  let keys = Object.keys(values);
  const len = keys.length;
  let recordedErrors = {};
  let value = ''
  for (let i = 0; i < len; i++) {
    value = values[keys[i]];
    if (!(value == '0') &&( !value || value === undefined || value.toString().trim().length <= 0)) {
      recordedErrors = {...recordedErrors, [keys[i]]: `${keys[i]} is required`}
    }
  }
  return (recordedErrors);
}

 
export const getDateFromDateTime = (date) => new Date(date).toLocaleDateString()
window.getDate = getDateFromDateTime

export const dateDisplayFormat = (date) => (new Date(date).toDateString()).substring(3);
window.dateDisplayFormat = dateDisplayFormat

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

window.addDays = addDays
window.subDays = subDays

export function subDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export function commaSeparateValue(value) {
  return value.toLocaleString();
}

export function generateRandomValue() {
  return (new Date().getTime()).toString(18)
}
 

export const helpClick = (data = [], handler) => {
  handler({open: true, data})
}
    
export const getTwoStatusColor = (status) => {
  let [statusText, statusColor] = status == 1 ? ['active', ' color-green'] : ['in-active', 'color-red'];
  return {statusText, statusColor}
}
export const getTwoCategoryPublishedColor = (published) => {
  let [publishedText, publishedColor] = published == 1 ? ['Published', ' color-green'] : ['Unpublished', 'color-red'];
  return {publishedText, publishedColor}
}
export const getTwoCategoryOrderingColor = (ordering) => {
  let [orderingText, orderingColor] = ordering == 1 ? ['Yes', ' color-green'] : ['No', 'color-red'];
  return {orderingText, orderingColor}
}
export const getTwoProductAddonColor = (is_addon) => {
  let [addonText, addonColor] = is_addon == 1 ? ['Yes', ' color-green'] : ['No', 'color-red'];
  return {addonText, addonColor}
}
export const getTwoProductDrinkColor = (is_drink) => {
  let [drinkText, drinkColor] = is_drink == 1 ? ['Yes', ' color-green'] : ['No', 'color-red'];
  return {drinkText, drinkColor}
}
export const getThreeOrderDeliveryStatusColor = (delivery_status) => {
  if(delivery_status == 1)
    return {deliveryStatusText: 'Placed', deliveryStatusColor:'color-orange'};
  if(delivery_status == 2)
    return {deliveryStatusText: 'Cooking', deliveryStatusColor:'color-blue'};
  if(delivery_status == 3)
    return {deliveryStatusText: 'Completed', deliveryStatusColor:'color-green'};
}
export const getTwoOrderSplitTypeColor = (split_type) => {
  let [splitTypeText, splitTypeColor] = split_type == 1 ? ['By Price', ' color-green'] : ['By Dishes', 'color-red'];
  return {splitTypeText, splitTypeColor}
}
export const getTwoOrderTypeColor = (type) => {
  let [typeText, typeColor] = type == 1 ? ['Dine In', ' color-green'] : ['Food Delivery', 'color-red'];
  return {typeText, typeColor}
}
export const getTwoOrderStatusColor = (status) => {
  // let [orderStatusText, orderStatusColor] = status == 1 ? ['Paid', ' color-green'] : ['Un Paid', 'color-red'];
  if(status == 0)
  return {orderStatusText: 'Cancel', orderStatusColor:'color-red'};
  if(status == 1)
    return {orderStatusText: 'Paid', orderStatusColor:'color-green'};
  if(status == 2)
    return {orderStatusText: 'Pending', orderStatusColor:'color-yellow'};
  if(status == 3)
    return {orderStatusText: 'Pay Later', orderStatusColor:'color-orange'};

  // return {orderStatusText, orderStatusColor}
}
export const getTwoGenderColor = (status) => {
  let [statusText, statusColor] = status == 1 ? ['Male', ' color-black'] : ['Female', 'color-pink'];
  return {statusText, statusColor}
}
export const getTwoPostStatusColor = (status) => {
  let [statusText, statusColor] = status == 1 ? ['Published', ' color-green'] : ['Unpublished', 'color-red'];
  return {statusText, statusColor}
}

export const getFileType = (type) => {
  let [statusText, typeColor] = type == 0 ? ['Embeded', ' color-green'] : ['Download', 'color-green'];
  return {statusText, typeColor}
}

export const getFilePreview = (file_preview) => {
  let [filePreviewText, filePreviewColor] = file_preview ==  1? ['Yes', ' color-green'] : ['No', 'color-red'];
  return {filePreviewText, filePreviewColor}
} 