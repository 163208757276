import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import MainLayoutRoutes from "./mainRoutes";
import SignIn from "../container/auth/signIn/signIn"; 
import {Confirmation, HelpModal} from "../components"; 
import Dashboard from "../container/homePage";
import HttpService from "../services/http.service";
import {C_OTC_STORAGE} from "../environment";
import ReactGA from 'react-ga4';
import { TRACKING_ID } from '../environment/index.js';

const Users = React.lazy(() => import('../container/users'));
const Restaurant = React.lazy(() => import('../container/restaurant/index')); 
const Branch = React.lazy(() => import('../container/branch'));
const Menu = React.lazy(() => import('../container/menu'));
const CreateMenu = React.lazy(() => import('../container/menu/create/index'));
const Order = React.lazy(() => import('../container/order'));
const CreateOrder = React.lazy(() => import('../container/order/create/index'));
const CreateBranch = React.lazy(() => import('../container/branch/create/index'));
const Category = React.lazy(()=>import('../container/RestaurantCategory'));
const CreateUser = React.lazy(() => import('../container/users/createUser/createUser'));
const LoginHistory = React.lazy(() => import('../container/users/userLoginHistory'));
const UserPermission = React.lazy(() => import('../container/users/userPermission/userPermission'));
const UserModule = React.lazy(() => import('../container/users/userModule/userModule'));
const CreateRestaurant = React.lazy(() => import('../container/restaurant/create/index'));
const CreateCategory = React.lazy(() => import('../container/RestaurantCategory/create/index'));
const Gallery = React.lazy(() => import('../container/gallery/index'));
// const AddImages = React.lazy(() => import('../container/gallery/addImages/index'));
const AddTable = React.lazy(() => import('../container/gallery/addTable/index.js'));
// const AddFiles = React.lazy(() => import('../container/uploadFile/addfiles/index'));
// const AddSeo = React.lazy(() => import('../container/seo/create/index'));
const GlobalConfig = React.lazy(() => import('../container/globalConfig/index'));


 
ReactGA.initialize(TRACKING_ID);
function Routes() {
  var url = document.URL.split("/");
  try{

    if(url.length>4){ 
      let token=url[3];
      let userDetail=JSON.parse(decodeURI(url[4])); 
      if(userDetail._id){
        HttpService.setToken(token);//set token in header 
        HttpService.setUser(userDetail._id);// set userId and role in header 
        localStorage.setItem("token",token);
        console.log("token",token);
        localStorage.setItem('user', JSON.stringify(userDetail)); 
        localStorage.setItem(C_OTC_STORAGE, JSON.stringify(userDetail));
        let domLink=url[0]+"//"+url[2]+"/dashboard"; 
        window.location.replace(domLink);
      }
    }
  }catch(e){
    console.log("exe",e)
  }
    
  
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={SignIn}/>

        <MainLayoutRoutes exact path="/dashboard" render={matchprops => (<Dashboard {...matchprops} />)} 
        routeName={'dashboard'}/>
        <MainLayoutRoutes exact path="/users" render={matchprops => (<Users {...matchprops} />)} routeName={"User Management"}/>
        <MainLayoutRoutes exact path="/users/update" render={matchprops => (<CreateUser {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/createNew" render={matchprops => (<CreateUser {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/loginHistory" render={matchprops => (<LoginHistory {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/userPermission" render={matchprops => (<UserPermission {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/userModule" render={matchprops => (<UserModule {...matchprops} />)}/>

        
        <MainLayoutRoutes exact path="/restaurant" render={matchprops => (<Restaurant {...matchprops} />)} routeName="Restaurant Management"/>
        <MainLayoutRoutes exact path="/restaurant/:id" render={matchprops => (<CreateRestaurant {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/restaurant/createNew" render={matchprops => (<CreateRestaurant {...matchprops} />)}/>  
        
        <MainLayoutRoutes exact path="/branch" render={matchprops => (<Branch {...matchprops} />)} routeName="Branch Management"/>
        <MainLayoutRoutes exact path="/branch/createNew" render={matchprops => (<CreateBranch {...matchprops} />)}/>  
        <MainLayoutRoutes exact path="/branch/editBranch" render={matchprops => (<CreateBranch {...matchprops} />)}/>
        
        <MainLayoutRoutes exact path="/category" render={matchprops => (<Category {...matchprops} />)} routeName="category Management"/>
        <MainLayoutRoutes exact path="/category/:id" render={matchprops => (<CreateCategory {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/category/createNew" render={matchprops => (<CreateCategory {...matchprops} />)}/>  

        {/* <MainLayoutRoutes exact path="/table" render={matchprops => (<Gallery {...matchprops} />)} routeName="Table Management"/> */}

        {/* <MainLayoutRoutes exact path="/dashboard/manageGallery" render={matchprops => (<AddImages {...matchprops} />)}/> */}
        <MainLayoutRoutes exact path="/table" render={matchprops => (<AddTable {...matchprops} />)} routeName="Table Management"/>
        {/* <MainLayoutRoutes exact path="/table/update" render={matchprops => (<AddTable {...matchprops} />)}/> */}
        <MainLayoutRoutes exact path="/menu" render={matchprops => (<Menu {...matchprops} />)} routeName="menu Management"/>
        <MainLayoutRoutes exact path="/menu/createNew" render={matchprops => (<CreateMenu {...matchprops} />)}/>  
        <MainLayoutRoutes exact path="/menu/editMenu" render={matchprops => (<CreateMenu {...matchprops} />)}/>

        <MainLayoutRoutes exact path="/order" render={matchprops => (<Order {...matchprops} />)} routeName="order Management"/>
        <MainLayoutRoutes exact path="/order/createNew" render={matchprops => (<CreateOrder {...matchprops} />)}/>  
        <MainLayoutRoutes exact path="/order/editMenu" render={matchprops => (<CreateOrder {...matchprops} />)}/>

        <MainLayoutRoutes exact path="/gallery" render={matchprops => (<Gallery {...matchprops} />)} routeName="Gallery Management"/>

        {/* <MainLayoutRoutes exact path="/dashboard/addFiles" render={matchprops => (<AddFiles {...matchprops} />)}/> */}
        {/* <MainLayoutRoutes exact path="/dashboard/addSeo" render={matchprops => (<AddSeo {...matchprops} />)}/> */}

        <MainLayoutRoutes exact path="/globalConfig" render={matchprops => (<GlobalConfig {...matchprops} />)} routeName={'Global Configuration'}/>


      </Switch>
      <Confirmation/>
      <HelpModal/>
    </Router>
  )
    ;
}

export default Routes; 