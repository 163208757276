import HttpService from "./http.service";

class GalleryService extends HttpService {
    list = (restaurant_branch_id) => this.getOrder(`table/table-list/${restaurant_branch_id}` );//params 
    // manageVideoLink = (params) => this.post("asset/manage-video", params);   
    // manageImage = (params,restaurant_id) => this.post(`restaurant/upload-resturant-logo/${restaurant_id}`, params);
    manageTable = (params) => this.postOrder('table/add-table',params);
    updateTable =(table_id,params) =>this.patchOrder(`table/update-table/${table_id}`,params);
    //deleteImage = (params) => this.post("asset/delete-image", params);
    //manageFile = (params) => this.postOrder(`category/add-category`, params);
    //listFiles = (params) => this.getOrder("category/categories-list",params); 
    //deleteFile = (params) => this.post("asset/delete-file", params);


  
  
}

export default new GalleryService();
