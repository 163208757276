import React, {useState} from "react";
import {Redirect, Route} from "react-router-dom";
// import internal(own) modules
import MainLayout from "./MainLayout";
import {
  C_OTC_STORAGE, 
  sidebarTabsList
} from "../environment";

const MainLayoutRoute = ({render, ...rest}) => {
  //console.log('In MainLayoutRoute ')

  const [user] = useState(JSON.parse(localStorage.getItem(C_OTC_STORAGE)));
  const role = user.role_id._id; 

  const sidebar =sidebarTabsList;
  const adminRoutes=['dashboard','users','gallery','restaurant','branch','category','menu','order','globalConfig','table'];
  const restuarantadmin =['dashboard','users','branch','category','menu','order','table'];
  const branchadmin =['dashboard','users','category','menu','order','table'];
  const countermanager =['dashboard','users','table','order'];//'category'
  const Kitchenmanager =['dashboard','users','order'];
  const waiter =['dashboard','users','order'];
  const customer =['dashboard'];

  return (
    user && role &&
    (
      ( adminRoutes.includes(rest.path.split('/')[1]) && (role == '661fa91d0f11708b9bc19d43')
        // && !user.mod?.includes(rest.routeName)
      ) ||
      (restuarantadmin.includes(rest.path.split('/')[1]) && (role == '661fa91d0f11708b9bc19d44')) 
      ||
      (branchadmin.includes(rest.path.split('/')[1]) && (role == '661fa91d0f11708b9bc19d45')) 
      ||
      (countermanager.includes(rest.path.split('/')[1]) && (role == '661fa91d0f11708b9bc19d46')) 
      ||
      (Kitchenmanager.includes(rest.path.split('/')[1]) && (role == '661fa91d0f11708b9bc19d47')) 
      ||
      (waiter.includes(rest.path.split('/')[1]) && (role == '661fa91d0f11708b9bc19d48')) 
      ||
      (customer.includes(rest.path.split('/')[1]) && (role == '661fa91d0f11708b9bc19d49')) 
    )
    
    ? 
    <Route
      {...rest}
      className={'app-logo-text'}
      render={matchProps => {
        return ( 
          <MainLayout sidebar={sidebar}>{render(matchProps)}</MainLayout> 
        )

      }}
    /> 
    :
    (<Redirect to={'/dashboard'}/>)
  );
};

export default MainLayoutRoute;
