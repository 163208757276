import OrderHttpService from "./order-http.service";

class MenuService extends OrderHttpService {
    list = (params) => this.get("product/products-list",params);

    add = (params) => this.post("product/add-products", params);

    update = (params,productId) => this.patch(`product/update-product/${productId}`,params);
}

export default new MenuService();