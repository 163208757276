import HttpService from "./http.service";

class AuthService extends HttpService {

    login = (data) => this.post("auth/login", data); //auth/login

    //verifyOtp = (data) => this.post("auth/verify-otp", data);

//    signup = (data) => this.post(`auth/signup-user`, data);

   signup = (data) => this.post(`users/admin-add-user`,data);


    listQuery = (params) => this.get(`public/auth/list-queries`, params);

    editUser = (data) => this.post(`updateProfile`, data);

    logout = (data) => this.get("logout");

    manageConfig = (data) => this.post(`public/auth/config`, data);

    getConfig = (data) => this.get(`public/auth/get-config`, data);


}

export default new AuthService();
