import {combineReducers} from "redux";
import {authReducer} from "./auth";
import {dashboardReducer} from "./dashboard";
import {usersReducer} from "./usersReudcer"; 
import {categoryReducer} from "./categoryReducer"; 
import {restaurantReducer} from "./restaurantReudcer"; 
import {branchReducer} from "./branchReducer"; 
// import {assetReducer} from "./assetReducer"; 
import {confirmationReducer} from './confirmationReducer'; 
import {helpReducer} from './helpReducer'; 
import {menuReducer} from "./menuReducer"; 
import {orderReducer} from "./orderReducer";
import {tableReducer} from "./tableReducer";
export const rootReducer = combineReducers({
  authReducer, dashboardReducer, usersReducer,categoryReducer,restaurantReducer,branchReducer,confirmationReducer, helpReducer, menuReducer,orderReducer,tableReducer
});
