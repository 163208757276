import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  menuList: null,
  menuListError: null,
  success: null,
  error: null, 
};
export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MENU_LIST_START:
      return { ...state, loading: true, error:null, menuListError: null, menuList: null };
    case actionTypes.GET_MENU_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        menuListError: null,
        menuList: action.payload,
      };
    };
    case actionTypes.GET_MENU_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        menuListError: action.payload,
        error:action.payload,
        menuList: null,
      };
    case actionTypes.ADD_MENU_START:
      return { ...state, loading: true, error: null, success: null };
    case actionTypes.ADD_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null,
      };
    };
    case actionTypes.ADD_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        success: null,
        error:action.payload
      };
    case actionTypes.UPDATE_MENU_START:
      return { ...state, loading: true, error: null, success: null };
    case actionTypes.UPDATE_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null,
      };
    };
    case actionTypes.UPDATE_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        success: null,
        error:action.payload
      };
 
    case actionTypes.MESSAGE_HANDLER:
      return {
        ...state,
        loading: false,
        menuListError: action.payload,
        error: action.payload,
        success: action.payload,
      };

    default:
      return { ...state };
  }
};
