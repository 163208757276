import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  orderList: null,
  orderListError: null,
  success: null,
  error: null, 
};
export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDER_LIST_START:
      return { ...state, loading: true, error:null, orderListError: null, orderList: null };
    case actionTypes.GET_ORDER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        orderListError: null,
        orderList: action.payload,
      };
    };
    case actionTypes.GET_ORDER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        orderListError: action.payload,
        error:action.payload,
        orderList: null,
      };
    case actionTypes.ADD_ORDER_START:
      return { ...state, loading: true, error: null, success: null };
    case actionTypes.ADD_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null,
      };
    };
    case actionTypes.ADD_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        success: null,
        error:action.payload
      };
    case actionTypes.UPDATE_ORDER_START:
      return { ...state, loading: true, error: null, success: null };
    case actionTypes.UPDATE_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null,
      };
    };
    case actionTypes.UPDATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        success: null,
        error:action.payload
      };
 
    case actionTypes.MESSAGE_HANDLER:
      return {
        ...state,
        loading: false,
        orderListError: action.payload,
        error: action.payload,
        success: action.payload,
      };

    default:
      return { ...state };
  }
};
