import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import UserService from '../../services/user.service'
import AuthService from '../../services/auth.service'

function* getUsersList(action) {
  try {
    console.log('getUsersList action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const role_id = action.payload?.roleName;
      const search = action.payload?.search;
      console.log("search in saga",search);
      const status = action.payload?.statusType;
      console.log("status in saga",status);
      // let params = { role_id};
      let params ={};
      params.page = page;
      params.perPage = perPage;
      params.role_id = role_id;
      if (search) {
        params.search = search;
      }
      if (status) {
        params.status = status;
      }
      //let params = {page, perPage, search, status};
      console.log("params and role_id of get user list",params,role_id);
      const response = yield UserService.list(
        role_id,
        params,
      );

      //console.log('getUsersList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.getUsersListSuccess(response.data));
      } else {
        yield put(actions.getUsersListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getUsersListFailure(error));
  }
}

function* getWaitersList(action) {
  try {
    console.log('getWaitesList action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const role_id = action.payload?.roleName;
      // const role_id = '6603d076b7b4f2a76d955764';
      const search = action.payload?.search;
      console.log("search in saga",search);
      const status = action.payload?.statusType;
      console.log("status in saga",status);
      // let params = { role_id};
      let params ={};
      params.page = page;
      params.perPage = perPage;
      params.role_id = role_id;
      if (search) {
        params.search = search;
      }
      if (status) {
        params.status = status;
      }
      //let params = {page, perPage, search, status};
      console.log("params and role_id of get user list",params,role_id);
      const response = yield UserService.list(
        role_id,
        params,
      );

      //console.log('getUsersList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        console.log('waiters list userSaga ',response.data);
        yield put(actions.getWaitersListSuccess(response.data));
      } else {
        yield put(actions.getWaitersListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getUsersListFailure(error));
  }
}
function* getUsersListSaga() {
  yield takeEvery(actionsTypes.GET_USERS_LIST_START, getUsersList);
}

function* getWaitersListSaga() {
  yield takeEvery(actionsTypes.GET_WAITERS_LIST_START, getWaitersList);
}

function* updateUserStart(action) {
  console.log('In userUpdate authSagas.js ',action.payload);
  const user_id = action.payload?._id;
  console.log("user-id ->",user_id);
  try {
    const response = yield UserService.editUser(
      action.payload,
      user_id,
    );
    console.log('update user response ',response.data);
    if (response.data?.statusCode==200) { 
      yield put(actions.updateUserSuccess(true));
    } else {
      yield put(actions.updateUserFailure(response.data.message));
    }
  } catch (err) {
    console.log('err userUpdate authSagas.js ', err)
    yield put(actions.updateUserFailure(err));
  }
}

function* updateUserStartSaga() {
  yield takeEvery(actionsTypes.UPDATE_USER_START, updateUserStart);
}

function* addNewUserStart(action) {
  console.log('In New user authSagas.js ',action.payload);
  try {
    const response = yield AuthService.signup(
      action.payload
    );
    console.log('New user  response ',response.data);
    if (response.data?.statusCode==200) { 
      yield put(actions.addNewUserSuccess(true));
    } else {
      yield put(actions.addNewUserFailure(response.data.message));
    }
  } catch (err) {
    console.log('err newUser authSagas.js ', err)
    yield put(actions.addNewUserFailure("Internal Server Error......"));
  }
}

function* addNewUserSaga() {
  yield takeEvery(actionsTypes.ADD_NEW_USER_START, addNewUserStart);
}


function* getUserPermissionList(action) {
  try {
    if (action.payload) {
      const response = yield UserService.listUserPermission(action.payload);
      if (response?.data?.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.listUserPermissionSuccess(response.data));
      } else {
        yield put(actions.listUserPermissionFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.listUserPermissionFailure(error));
  }
}

function* getUserPermissionListSaga() {
  yield takeEvery(actionsTypes.LIST_USER_PERMISSION_START, getUserPermissionList);
}


function* manageUserPermission(action) {
  try {
    if (action.payload) {
      const {data, params}=action.payload;
      const response = yield UserService.manageUserPermission(data, params);
      if (response?.data?.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.manageUserPermissionSuccess(response.data));
      } else {
        yield put(actions.manageUserPermissionFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.manageUserPermissionFailure(error));
  }
}

function* manageUserPermissionSaga() {
  yield takeEvery(actionsTypes.MANAGE_USER_PERMISSION_START, manageUserPermission);
}


function* getModuleList(action) {
  try {
    if (action.payload) {
      const response = yield UserService.listModules(action.payload);
      if (response?.data?.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.listModuleSuccess(response.data));
      } else {
        yield put(actions.listModuleFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.listModuleFailure(error));
  }
}

function* listModuleSaga() {
  yield takeEvery(actionsTypes.LIST_MODULE_START, getModuleList);
}


function* listLoginHistory(action)  {
  try {
    //console.log('getUsersList action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const user_id = action.payload?.user_id;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      let params = {page, perPage, user_id, search, status};

      const response = yield UserService.listLoginHistory(params);
      console.log('getUsersList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.listLoginHistorySuccess(response.data));
      } else {
        yield put(actions.listLoginHistoryFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.listLoginHistoryFailure(error));
  }
}

function* listLoginHistorySaga() {
  yield takeEvery(actionsTypes.LIST_LOGIN_HISTORY_START, listLoginHistory);
}

export function* userSaga() {
  yield all([fork(getUsersListSaga), fork(getWaitersListSaga),fork(updateUserStartSaga), fork(addNewUserSaga), fork(getUserPermissionListSaga), fork(manageUserPermissionSaga), fork(listModuleSaga), fork(listLoginHistorySaga) ]);
}
